import {
  PLAYER_NOTE_FAILURE,
  PLAYER_NOTE_REQUEST,
  PLAYER_NOTE_SAVE_FAILURE,
  PLAYER_NOTE_SAVE_REQUEST,
  PLAYER_NOTE_SAVE_SUCCESS,
  PLAYER_NOTE_SUCCESS,
  PLAYER_NOTES_FAILURE,
  PLAYER_NOTES_PRIORITIES_FAILURE,
  PLAYER_NOTES_PRIORITIES_REQUEST,
  PLAYER_NOTES_PRIORITIES_SUCCESS,
  PLAYER_NOTES_REQUEST,
  PLAYER_NOTES_SUCCESS,
  PLAYER_NOTES_TYPES_FAILURE,
  PLAYER_NOTES_TYPES_REQUEST,
  PLAYER_NOTES_TYPES_SUCCESS,
} from "../../../constants";
import React from "react";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Moment from "react-moment";

const statusMapping = {
  1: {
    badgeId: "5",
    badgeLabel: "playerNotes.priorityTypes.critical",
  },
  2: {
    badgeId: "3",
    badgeLabel: "playerNotes.priorityTypes.high",
  },
  3: {
    badgeId: "2",
    badgeLabel: "playerNotes.priorityTypes.normal",
  },
};

const initialState = {
  playerNote: {
    data: null,
    loading: true,
    success: null,
    columns: [
      {
        field: "priorityId",
        default: true,
        title: "label.player.playerNotes.priority",
        render: (rowData) => {
          if (rowData["priorityId"]) {
            const badgeMapped = statusMapping[rowData["priorityId"]];
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
      },
      {
        field: "value",
        title: "label.player.playerNotes.message",
      },
      {
        field: "noteName",
        default: true,
        title: "label.player.playerNotes.type",
      },
      {
        field: "owner",
        title: "label.player.playerNotes.user",
      },
      {
        field: "group",
        title: "label.player.playerNotes.role",
      },
      {
        field: "dateLastUpdate",
        defaultSort: "desc",
        title: "label.player.playerNotes.lastUpdate",
        render: (rowData) => {
          if (rowData["dateLastUpdate"]) {
            return <Moment format="L LT">{rowData["dateLastUpdate"]}</Moment>;
          }
        },
      },
    ],
  },
  playerNotes: {
    types: [],
    priorities: [],
    loading: true,
    data: [],
    columns: [
      {
        field: "priorityId",
        default: true,
        title: "label.player.playerNotes.priority",
        render: (rowData) => {
          if (rowData["priorityId"]) {
            const badgeMapped = statusMapping[rowData["priorityId"]];
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
      },
      {
        field: "value",
        title: "label.player.playerNotes.message",
      },
      {
        field: "noteName",
        default: true,
        title: "label.player.playerNotes.type",
      },
      {
        field: "owner",
        title: "label.player.playerNotes.user",
      },
      {
        field: "group",
        title: "label.player.playerNotes.role",
      },
      {
        field: "lastUpdateDate",
        defaultSort: "desc",
        title: "label.player.playerNotes.lastUpdate",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
      },
    ],
    fields: [
      {
        field: "priorityId",
        title: "label.player.playerNotes.priority",
        render: (rowData) => {
          if (rowData["priorityId"]) {
            const badgeMapped = statusMapping[rowData["priorityId"]];
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
      },
      {
        field: "lastUpdateDate",
        title: "label.player.playerNotes.lastUpdate",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
      },
      {
        field: "noteName",
        title: "label.player.playerNotes.type",
      },
      {
        field: "value",
        title: "label.player.playerNotes.message",
      },
    ],
  },
};

const PlayerNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_NOTES_REQUEST: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          data: null,
          loading: true,
        },
        playerNote: {
          ...state.playerNote,
          success: null,
        },
      };
    }
    case PLAYER_NOTES_SUCCESS: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          data: action.payload,
          loading: false,
        },
      };
    }
    case PLAYER_NOTES_FAILURE: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          data: null,
          loading: false,
        },
      };
    }
    case PLAYER_NOTES_PRIORITIES_REQUEST: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          priorities: null,
        },
      };
    }
    case PLAYER_NOTES_PRIORITIES_SUCCESS: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          priorities: action.payload,
        },
      };
    }
    case PLAYER_NOTES_PRIORITIES_FAILURE: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          priorities: null,
        },
      };
    }

    case PLAYER_NOTES_TYPES_REQUEST: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          types: null,
        },
      };
    }
    case PLAYER_NOTES_TYPES_SUCCESS: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          types: action.payload,
        },
      };
    }
    case PLAYER_NOTES_TYPES_FAILURE: {
      return {
        ...state,
        playerNotes: {
          ...state.playerNotes,
          types: null,
        },
      };
    }

    case PLAYER_NOTE_REQUEST: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          data: null,
          loading: true,
        },
      };
    }
    case PLAYER_NOTE_SUCCESS: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          data: action.payload,
          loading: false,
        },
      };
    }
    case PLAYER_NOTE_FAILURE: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          data: null,
          loading: false,
        },
      };
    }

    case PLAYER_NOTE_SAVE_REQUEST: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          success: null,
        },
      };
    }
    case PLAYER_NOTE_SAVE_FAILURE: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          success: false,
        },
      };
    }
    case PLAYER_NOTE_SAVE_SUCCESS: {
      return {
        ...state,
        playerNote: {
          ...state.playerNote,
          success: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default PlayerNotesReducer;
