import * as moment from "moment";
import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import {
  getEmailValidation,
  getRequiredValidation,
  getValidationByType,
} from "../../../../Layouts/form/utils";
import {
  LOTTERY_BLOCKLIST_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_REQUEST,
  LOTTERY_BLOCKLIST_FILTER_SUCCESS,
  LOTTERY_BLOCKLIST_REQUEST,
  LOTTERY_BLOCKLIST_SUCCESS,
} from "../../../constants";
import { isEmpty, isNumber } from "lodash";
import Translate from "../../../../Layouts/Translator/Translate";


export const labelPrefix = "label.admin.lotteryBlocklist.";

const initialState = {
  lotteryBlocklist: {
    data: null,
    isLoading: true,
    columns: (ssn4Enabled) => {
      let cols = [
        {
          field: "ssn",
          title: labelPrefix + "ssn",
          editComponentDef: (row) => {
            return {
              name: "ssn",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.ssn,
              validation: getValidationByType("Text").when([ "mail", "username", "phone"], {
                is: (mail, username,phone) => {
                  return isEmpty(mail) && isEmpty(username) && !isNumber(phone);
                },
                then: ssn4Enabled ? getRequiredValidation(
                  getValidationByType("Text").min(4,<Translate
                    needle="errors.minLength"
                    variables={[4]}
                  />).nullable(),
                  "Text",
                  labelPrefix,
                  "ssn"
                ): getRequiredValidation(
                  getValidationByType("Text").nullable(),
                  "Text",
                  labelPrefix,
                  "ssn",),
                otherwise: ssn4Enabled ?
                  getValidationByType("Text").min(4,<Translate
                    needle="errors.minLength"
                    variables={[4]}
                  />).nullable(): getValidationByType("Text").nullable(),
              }).max(250,<Translate
                needle="errors.maxLength"
                variables={[250]}
              />),
          
            };
          },
        },
        {
          field: "firstName",
          title: labelPrefix + "firstName",
          hidden: !ssn4Enabled,
          editComponentDef: (row) => {
            return {
              name: "firstName",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.firstName,
              validation: getValidationByType("Text").when([ "ssn","mail", "username", "phone"], {
                is: (ssn,mail, username,phone) => {
                  return ssn4Enabled && isEmpty(username) && isEmpty(mail) && !isNumber(phone) && ssn;
                },
                then: getRequiredValidation(
                  getValidationByType("Text").nullable(),
                  "Text",
                  labelPrefix,
                  "firstName"
                ),
                otherwise: getValidationByType("Text").nullable(),
              }),
            };
          },
        },
        {
          field: "lastName",
          title: labelPrefix + "lastName",
          hidden: !ssn4Enabled,
          editComponentDef: (row) => {
            return {
              name: "lastName",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.lastName,
              validation: getValidationByType("Text").when(["ssn","mail", "username", "phone"], {
                is: (ssn,mail, username,phone) => {
                  return ssn4Enabled && isEmpty(username) && isEmpty(mail) && !isNumber(phone) && ssn;
                },
                then: getRequiredValidation(
                  getValidationByType("Text").nullable(),
                  "Text",
                  labelPrefix,
                  "lastName"
                ),
                otherwise: getValidationByType("Text").nullable(),
              }),
            };
          },
        },
        {
          field: "dateOfBirth",
          title: labelPrefix + "dateOfBirth",
          hidden: !ssn4Enabled,
          cellStyle: { minWidth: "160px"},
          editComponentDef: (row) => {
            return {
              name: "dateOfBirth",
              labelPrefix: labelPrefix,
              type: "DatePicker",
              initialValue: row.dateOfBirth,
              validation: getValidationByType("DatePicker").when(["ssn","mail", "username", "phone"], {
                is: (ssn,mail, username,phone) => {
                  return ssn4Enabled && isEmpty(username) && isEmpty(mail) && !isNumber(phone) && ssn;
                },
                then: getRequiredValidation(
                  getValidationByType("DatePicker"),
                  "DatePicker",
                  labelPrefix,
                  "dateOfBirth"
                ),
                otherwise: getValidationByType("DatePicker"),
              }),
            };
          },
          render: (rowData) => {
            if (rowData["dateOfBirth"]) {
              return (
                <DateFormatter
                  utc
                  format={"L"}
                  value={rowData["dateOfBirth"]}
                />
              );
            }
          },
          getExportValueFn: (rowData) => {
            if (rowData["dateOfBirth"]) {
              return moment.utc(+rowData["dateOfBirth"]).format("L");
            }
          },
        },
        {
          field: "username",
          title: labelPrefix + "username",
          editComponentDef: (row) => {
            return {
              name: "username",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.username,
              validation: getValidationByType("Text").when([ "mail", "ssn", "phone"], {
                is: ( mail, ssn, phone) => {
                  return  isEmpty(mail) && isEmpty(ssn) && !isNumber(phone);
                },
                then: getRequiredValidation(
                  getValidationByType("Text").nullable(),
                  "Text",
                  labelPrefix,
                  "username"
                ),
                otherwise: getValidationByType("Text").nullable(),
              }),
          
            };
          },
        },
        {
          field: "mail",
          title: labelPrefix + "mail",
          editComponentDef: (row) => {
            return {
              name: "mail",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.mail,
              validation: getValidationByType("Text").when(["username", "ssn", "phone"], {
                is: (username, ssn, phone) => {
                  return isEmpty(username) && isEmpty(ssn) && !isNumber(phone);
                },
                then: getRequiredValidation(
                  getEmailValidation(getValidationByType("Text")),
                  "Text",
                  labelPrefix,
                  "mail"
                ),
                otherwise: getValidationByType("Text"),
              }),
            };
          },
        },
        {
          field: "phone",
          title: labelPrefix + "phone",
          editComponentDef: (row) => {
            return {
              name: "phone",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.phone,
              validation: getValidationByType("Integer").when(["mail", "ssn", "username"], {
                is: (mail, ssn, username) => {
                  return isEmpty(mail) && isEmpty(ssn) && isEmpty(username);
                },
                then: getRequiredValidation(
                  getValidationByType("Integer"),
                  "Integer",
                  labelPrefix,
                  "phone"
                ),
                otherwise: getValidationByType("Integer"),
              }),
            };
          },
        },
        { field: "type", title: labelPrefix + "type", editable: false },
        { field: "fileName", title: labelPrefix + "fileName", editable: false },
        { field: "boUser", title: labelPrefix + "boUser", editable: false },
        {
          field: "reason",
          title: labelPrefix + "reason",
          nowrap: true,
          editComponentDef: (row) => {
            return {
              name: "reason",
              labelPrefix: labelPrefix,
              type: "Text",
              initialValue: row.reason,
              validation: getValidationByType("Text"),
            };
          },
        },
      ];

      return cols.filter((it) => !it.hidden);
    },
  },
};

const LotteryBlocklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOTTERY_BLOCKLIST_SUCCESS:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          data: action.payload,
          isLoading: false,
        },
      };
    case LOTTERY_BLOCKLIST_FAILURE:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          data: null,
          isLoading: false,
        },
      };
    case LOTTERY_BLOCKLIST_FILTER_REQUEST:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          filters: [],
          filtersLoading: true,
        },
      };
    case LOTTERY_BLOCKLIST_FILTER_SUCCESS:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          filters: action.payload,
          filtersLoading: false,
        },
      };

    case LOTTERY_BLOCKLIST_REQUEST:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          isLoading: true,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default LotteryBlocklistReducer;
