import { call, put } from "redux-saga/effects";
import {
  BUDGET_DETAILS_REQUEST,
  CAMPAIGN_CHANGE_STATUS_REQUEST,
  CAMPAIGN_DETAILS_FAILURE,
  CAMPAIGN_DETAILS_REQUEST,
  CAMPAIGN_DETAILS_SUCCESS,
  CAMPAIGN_MANAGEMENT_LIST_RELOAD,
  CAMPAIGN_PARAMETER_DEFINITIONS_FAILURE,
  CAMPAIGN_PARAMETER_DEFINITIONS_REQUEST,
  CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS,
  CAMPAIGN_PARAMETER_TYPE_OPERATOR_FAILURE,
  CAMPAIGN_PARAMETER_TYPE_OPERATOR_REQUEST,
  CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS,
  CAMPAIGN_REWARD_BUDGET_TYPES_FAILURE,
  CAMPAIGN_REWARD_BUDGET_TYPES_REQUEST,
  CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS,
  CAMPAIGN_REWARD_EVENT_TYPES_FAILURE,
  CAMPAIGN_REWARD_EVENT_TYPES_REQUEST,
  CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS,
  CAMPAIGN_REWARD_EXCLUDED_PLAYERS_FAILURE,
  CAMPAIGN_REWARD_EXCLUDED_PLAYERS_REQUEST,
  CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS,
  CAMPAIGN_REWARD_RULE_FAILURE,
  CAMPAIGN_REWARD_RULE_REQUEST,
  CAMPAIGN_REWARD_RULE_SUCCESS,
  CAMPAIGN_REWARD_TYPES_FAILURE,
  CAMPAIGN_REWARD_TYPES_REQUEST,
  CAMPAIGN_REWARD_TYPES_SUCCESS,
  CAMPAIGN_TAGS_FAILURE,
  CAMPAIGN_TAGS_REQUEST,
  CAMPAIGN_TAGS_SUCCESS,
  MANAGEMENT_LIST_FAILURE,
  MANAGEMENT_LIST_FILTERS_REQUEST,
  MANAGEMENT_LIST_FILTERS_SUCCESS,
  MANAGEMENT_LIST_FILTERS_FAILURE,
  MANAGEMENT_LIST_REQUEST,
  MANAGEMENT_LIST_SUCCESS,
  PROMO_CODES_DETAILS_FAILURE,
  PROMO_CODES_DETAILS_REQUEST,
  PROMO_CODES_DETAILS_SUCCESS,
  REF_DETAILS_REQUEST,
  REWARDS_DETAILS_FAILURE,
  REWARDS_DETAILS_SUCCESS,
  CAMPAIGN_ACCEPTANCE_TYPE_REQUEST,
  CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS,
  CAMPAIGN_ACCEPTANCE_TYPE_FAILURE,
  CAMPAIGN_GET_COMMUNICATIONS_REQUEST,
  CAMPAIGN_COMMUNICATIONS_SUCCESS,
  CAMPAIGN_COMMUNICATIONS_FAILURE,
  CAMPAIGN_SAVE_REQUEST,
  CAMPAIGN_SAVE_SUCCESS,
  CAMPAIGN_SAVE_FAILURE,
  CAMPAIGN_FULL_DETAILS_REQUEST,
  CAMPAIGN_FULL_DETAILS_SUCCESS,
  CAMPAIGN_FULL_DETAILS_FAILURE,
  GO_TO_ERROR_PAGE,
  CAMPAIGN_CUSTOM_REWARD_REQUEST,
  CAMPAIGN_CUSTOM_REWARD_SUCCESS,
  CAMPAIGN_CUSTOM_REWARD_FAILURE,
} from "../../../constants";
import campaignManagementService from "../campaign-management/campaign-managemet-service";
import { select, takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadListFilter = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getListFilter,
      action.ganttView
    );
    yield put({
      type: MANAGEMENT_LIST_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign management filter", e);
    yield put({
      type: MANAGEMENT_LIST_FILTERS_FAILURE,
    });
  }
};

const loadListData = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getListData,
      action.brand.id,
      action.partner.id,
      action.searchTerms
    );
    yield put({
      type: MANAGEMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign managemement list ", e);
    yield put({
      type: MANAGEMENT_LIST_FAILURE,
    });
  }
};

const loadRewardTypes = function* (action) {
  try {
    const data = yield call(campaignManagementService.getRewardTypes);
    yield put({
      type: CAMPAIGN_REWARD_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadRewardTypes", e);
    yield put({
      type: CAMPAIGN_REWARD_TYPES_FAILURE,
    });
  }
};

const loadRewardEventTypes = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getRewardEventTypes,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadRewardEventTypes", e);
    yield put({
      type: CAMPAIGN_REWARD_EVENT_TYPES_FAILURE,
    });
  }
};

const loadRewardBudgetTypes = function* (action) {
  try {
    const data = yield call(campaignManagementService.getBudgetTypes);
    yield put({
      type: CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadRewardBudgetTypes", e);
    yield put({
      type: CAMPAIGN_REWARD_BUDGET_TYPES_FAILURE,
    });
  }
};

const loadRewardExcludedPlayers = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getExclusionCampaignForPlayerAwarding,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadRewardExcludedPlayers", e);
    yield put({
      type: CAMPAIGN_REWARD_EXCLUDED_PLAYERS_FAILURE,
    });
  }
};

const loadCampaignDetails = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getCampaignDetails,
      action.campaignId
    );
    data["campaignId"] = action.campaignId;
    yield put({
      type: CAMPAIGN_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign managemement details ", e);
    yield put({
      type: CAMPAIGN_DETAILS_FAILURE,
    });
  }
};

const loadBudgetDetails = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getBudgetDetails,
      action.brand.id,
      action.partner.id,
      action.campaignId,
      action.pending
    );
    yield put({
      type: REWARDS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign budget details ", e);
    yield put({
      type: REWARDS_DETAILS_FAILURE,
    });
  }
};

const loadRefDetails = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getRefDetails,
      action.brand.id,
      action.partner.id,
      action.campaignId,
      action.pending
    );
    yield put({
      type: REWARDS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign ref details ", e);
    yield put({
      type: REWARDS_DETAILS_FAILURE,
    });
  }
};

const loadPromoCodesDetails = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getPromoCodesDetails,
      action.brand.id,
      action.partner.id,
      action.campaignId,
      action.status
    );
    yield put({
      type: PROMO_CODES_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaign promo codes details ", e);
    yield put({
      type: PROMO_CODES_DETAILS_FAILURE,
    });
  }
};

const loadTags = function* (action) {
  try {
    const data = yield call(campaignManagementService.getTags, action.brand.id, action.partner.id);
    yield put({
      type: CAMPAIGN_TAGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadTags", e);
    yield put({
      type: CAMPAIGN_TAGS_FAILURE,
    });
  }
};

const loadParameterTypeOperator = function* () {
  try {
    const data = yield call(campaignManagementService.getParameterTypeOperator);
    yield put({
      type: CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadParameterTypeOperator", e);
    yield put({
      type: CAMPAIGN_PARAMETER_TYPE_OPERATOR_FAILURE,
    });
  }
};

const loadParameterDefinitions = function* ({
  brand,
  partner,
  eventType,
  step,
}) {
  try {
    const data = yield call(
      campaignManagementService.getParameterDefinitions,
      brand.id,
      partner.id,
      eventType,
      step
    );
    yield put({
      type: CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadParameterDefinitions", e);
    yield put({
      type: CAMPAIGN_PARAMETER_DEFINITIONS_FAILURE,
    });
  }
};

const changeCampaignStatus = function* ({
  brand,
  partner,
  campaignId,
  status,
  force,
  cb,
  errcb,
}) {
  try {
    yield call(
      campaignManagementService.changeStatus,
      brand.id,
      partner.id,
      campaignId,
      status,
      force
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.rewards.campaigns.campaignManagement.campaignDetails.modal." +
            status +
            "_success",
        },
        options: {
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: CAMPAIGN_DETAILS_REQUEST,
      campaignId,
    });
    yield put({
      type: CAMPAIGN_MANAGEMENT_LIST_RELOAD,
    });
    cb != null && typeof cb === "function" && cb();
  } catch (e) {
    if (e.response && e.response["data"] !== "") {
      if (e.response["data"].indexOf("warning") !== -1) {
        const warningMessage = e.response["data"].substring(
          0,
          e.response["data"].indexOf("#")
        );
        cb != null && typeof cb === "function" && cb();
        errcb != null &&
          typeof errcb === "function" &&
          errcb(warningMessage, status);
        return false;
      }
    }
  }
};

const getCommunications = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getCommunications,
      action.brand,
      action.partner,
      action.hasPlayerGroup
    );
    yield put({
      type: CAMPAIGN_COMMUNICATIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("communicationsList", e);
    yield put({
      type: CAMPAIGN_COMMUNICATIONS_FAILURE,
    });
  }
};

const reloadList = function* () {
  const lastRequest = yield select(
    (state) => state.CampaignManagementReducer.managementList.lastRequest
  );
  yield put(lastRequest);
};

const acceptanceType = function* (action) {
  try {
    const data = yield call(campaignManagementService.acceptanceType);
    yield put({
      type: CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaignAcceptanceType", e);
    yield put({
      type: CAMPAIGN_ACCEPTANCE_TYPE_FAILURE,
    });
  }
};

const loadRewardRule = function* ({
  brand,
  partner,
  eventType,
  rewardType,
  step,
}) {
  try {
    const data = yield call(
      campaignManagementService.getRewardRule,
      brand.id,
      partner.id,
      eventType,
      rewardType,
      step
    );
    yield put({
      type: CAMPAIGN_REWARD_RULE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadRewardRule", e);
    yield put({
      type: CAMPAIGN_REWARD_RULE_FAILURE,
    });
  }
};

const saveCampaign = function* ({ body, cb, errCb }) {
  try {
    yield call(campaignManagementService.saveCampaign, body);
    yield put({
      type: CAMPAIGN_SAVE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            body.campaignId != null ? "ok.campaign.create" : "ok.campaign.edit",
        },
        options: {
          key: "saveCampaign",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    cb != null && typeof cb === "function" && cb();
    yield put({
      type: CAMPAIGN_MANAGEMENT_LIST_RELOAD,
    });
    if (body.campaignId != null) {
      yield put({
        type: CAMPAIGN_DETAILS_REQUEST,
        campaignId: body.campaignId,
      });
    }
  } catch (e) {
    console.error("saveCampaign", e);
    if (
      e.response &&
      e.response["data"] !== "" &&
      e.response["data"].indexOf("warning") !== -1
    ) {
      errCb != null && typeof errCb === "function" && errCb(e.response.data);
      return false;
    }
    errCb != null && typeof errCb === "function" && errCb();
    yield put({
      type: CAMPAIGN_SAVE_FAILURE,
    });
  }
};

const loadCampaignFullDetails = function* (action) {
  try {
    const data = yield call(
      campaignManagementService.getCampaignFullDetails,
      action.brand.id,
      action.partner.id,
      action.campaignId
    );
    yield put({
      type: CAMPAIGN_FULL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadCampaignFullDetails", e);
    yield put({
      type: CAMPAIGN_FULL_DETAILS_FAILURE,
    });
    yield put({
      type: GO_TO_ERROR_PAGE,
      payload: action.campaignId,
    });
  }
};

const loadCustomRewardDefinitions = function* ({ brand, partner, rewardType }) {
  try {
    const data = yield call(
      campaignManagementService.getCustomRewardDefinitions,
      brand.id,
      partner.id,
      rewardType
    );
    yield put({
      type: CAMPAIGN_CUSTOM_REWARD_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("customRewardDefinitions", e);
    yield put({
      type: CAMPAIGN_CUSTOM_REWARD_FAILURE,
    });
  }
};

const CampaignManagementSaga = function* CampaignManagementSaga() {
  yield takeLatest(MANAGEMENT_LIST_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(MANAGEMENT_LIST_REQUEST, loadListData);
  yield takeLatest(CAMPAIGN_DETAILS_REQUEST, loadCampaignDetails);
  yield takeLatest(BUDGET_DETAILS_REQUEST, loadBudgetDetails);
  yield takeLatest(REF_DETAILS_REQUEST, loadRefDetails);
  yield takeLatest(PROMO_CODES_DETAILS_REQUEST, loadPromoCodesDetails);
  yield takeLatest(CAMPAIGN_REWARD_TYPES_REQUEST, loadRewardTypes);
  yield takeLatest(CAMPAIGN_REWARD_EVENT_TYPES_REQUEST, loadRewardEventTypes);
  yield takeLatest(CAMPAIGN_REWARD_BUDGET_TYPES_REQUEST, loadRewardBudgetTypes);
  yield takeLatest(
    CAMPAIGN_REWARD_EXCLUDED_PLAYERS_REQUEST,
    loadRewardExcludedPlayers
  );
  yield takeLatest(CAMPAIGN_TAGS_REQUEST, loadTags);
  yield takeLatest(
    CAMPAIGN_PARAMETER_TYPE_OPERATOR_REQUEST,
    loadParameterTypeOperator
  );
  yield takeLatest(
    CAMPAIGN_PARAMETER_DEFINITIONS_REQUEST,
    loadParameterDefinitions
  );
  yield takeLatest(CAMPAIGN_CHANGE_STATUS_REQUEST, changeCampaignStatus);
  yield takeLatest(CAMPAIGN_MANAGEMENT_LIST_RELOAD, reloadList);
  yield takeLatest(CAMPAIGN_REWARD_RULE_REQUEST, loadRewardRule);
  yield takeLatest(CAMPAIGN_ACCEPTANCE_TYPE_REQUEST, acceptanceType);
  yield takeLatest(CAMPAIGN_GET_COMMUNICATIONS_REQUEST, getCommunications);
  yield takeLatest(CAMPAIGN_SAVE_REQUEST, saveCampaign);
  yield takeLatest(CAMPAIGN_FULL_DETAILS_REQUEST, loadCampaignFullDetails);
  yield takeLatest(CAMPAIGN_CUSTOM_REWARD_REQUEST, loadCustomRewardDefinitions);
};

export default CampaignManagementSaga;
