import axios from "axios";
import { CRITERIA_TYPE_DATE, CRITERIA_TYPE_SELECT } from "../../../constants";
import searchUtils from "../../../../utilities/searchUtils";
import { labelPrefix } from "./promo-code-reducer";

class PromoCodeService {
  getPromoCodeFilters = async (brand, partner) => {
    const filter = [
      {
        name: "promoCodeId",
        label: labelPrefix + "promoCodeId",
        selected: true,
      },
    ];

    const campaignList = await this.getAvalaibleCampaigns(brand, partner);
    if (campaignList) {
      let campaignFilter = {
        name: "campaignId",
        label: labelPrefix + "campaignId",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: campaignList.map((campaign) => {
          return {
            label: campaign.campaignId + " - " + campaign.name,
            value: campaign.campaignId,
          };
        }),
      };
      filter.push(campaignFilter);
    }

    filter.push(
      {
        name: "activationDate",
        label: labelPrefix + "activationDate",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "expirationDate",
        label: labelPrefix + "expirationDate",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "promoType",
        label: labelPrefix + "promoType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
            {label: "OPTIN", value: "OPTIN"},
            {label: "REWARD", value: "REWARD"},
        ],
      }
    );

    return filter;
  };

  getAvalaibleCampaigns = async (brand, partner) => {
    const url = `/gov/api/rest/v1/promocode/available-campaigns?brand=${brand}&partner=${partner}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data.sort((a, b) => a.name.localeCompare(b.name));
    }
    throw Error("Error getting campaigns");
  };

  getPromoCodeList = async (searchTerms) => {
    const url = "/gov/api/rest/v1/promocode";
    const terms = searchUtils.sanitizeTerms(searchTerms);

    const response = await axios.get(url, {
      params: { ...terms },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response promo code list");
  };

  async getCampaigns(brand, partner, promoType) {
    const response = await axios.get(
      `/gov/api/rest/v1/promocode/campaigns?brand=${brand}&partner=${partner}&promoType=${promoType}`
    );

    if (response && response.data) {
      return response.data.sort((a, b) => a.name.localeCompare(b.name));
    }
    throw Error("Invalid response for campaign list");
  }

  async getAmountTypes() {
    const response = await axios.get(`/gov/api/rest/v1/promocode/amount-type`);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for amount types");
  }

  async savePromoCode(body) {
    if (+body["numberOfPromoCode"] < 2) {
      delete body["prefix"];
    }

    if (+body["numberOfPromoCode"] !== 1) {
      delete body["promoCodeId"];
    }

    if (body["amountType"] && body["amountType"] !== "BONUS_CASH") {
      delete body["gameDomain"];
    }

    if (body["amountType"] && body["amountType"] === "DISABLED") {
      delete body["currency"];
      delete body["playerRewardAmount"];
      delete body["amount"];
    }

    if (+body["mandatoryForCampaign"] == true) {
      delete body["activationDate"];
      delete body["expirationDate"];
    }

    delete body["_meta"];

    const response = await axios.post(`/gov/api/rest/v1/promocode`, body);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for save promo code");
  }
}

const promoCodeService = new PromoCodeService();
export default promoCodeService;
