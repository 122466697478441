import moment from "moment";

class SearchUtils {
  escapeCharacters(str) {
    return str ? (""+str).replace(/[()[\]{}!^?\-+:\\"\s]/g, "\\$&") : str;
  }

  getBasicLuceneQuery(searchTerms) {
    let terms = searchTerms;
    if (searchTerms.constructor.name === "Object") {
      terms = Object.keys(searchTerms);
    }
    const query = terms
      .filter((it) => searchTerms[it] !== "" && searchTerms[it] != null)
      .reduce((accumulator, key) => {
        if (Array.isArray(searchTerms[key])) {
          if (searchTerms[key].length > 0) {
            const values = searchTerms[key].map((it) => {
              const value = this.escapeCharacters(it);
              return value.indexOf("*") === -1
                ? `${key}:"${value}"`
                : `${key}:${value}`;
            });
            accumulator.push("(" + values.join(" OR ") + ")");
          }
        } else {
          let value = searchTerms[key];
          if(key=='FULLNAME'){
            if(value.indexOf(" ") >0){
                var tempValue = value.split(" ");
                var finalVal = "(";
                for(var i=0 ; i<= tempValue.length -1 ; i++){
                  finalVal = finalVal +tempValue[i]+ ((tempValue.length -1 !== i) ?" AND ": "");
                }
                value = finalVal +")";
            }
            }
          if (key != "EMAIL") {
            value = this.escapeCharacters(value);
          }
          ((value.indexOf("*") === -1) && key!=='FULLNAME' && value.indexOf('[') === -1)
            ? accumulator.push(`${key}:"${value}"`)
            : accumulator.push(`${key}:${value}`);
        }
        return accumulator;
      }, [])

      .join(" AND ");

    return query.trim();
  }

  isObject(obj) {
    return obj != null && obj.constructor.name === "Object";
  }

  filterData(it, searchTerms) {
    let toReturn = true;
    Object.entries(searchTerms)
      .filter(
        ([key, value]) =>
          value && value !== "" && (!Array.isArray(value) || value.length > 0)
      )
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          let arrayReturn = false;
          value.forEach((v) => {
            arrayReturn = arrayReturn || it[key] === v;
          });
          toReturn = toReturn && arrayReturn;
        } else if (this.isObject(value)) {
          if (value.from && value.to) {
            toReturn = toReturn && it[key] >= value.from && it[key] <= value.to;
          }
          if (value.from && !value.to) {
            toReturn = toReturn && it[key] >= value.from;
          }
          if (!value.from && value.to) {
            toReturn = toReturn && it[key] <= value.to;
          }
        } else {
          toReturn = toReturn && it[key] + "" === value + "";
        }
      });
    return toReturn;
  }

  search(data, searchTerms) {
    if (searchTerms) {
      return data.filter((it) => this.filterData(it, searchTerms));
    } else {
      return data;
    }
  }

  getQueryParam(searchTerms, otherQueryParamsPresent, allValues = false) {
    let query = Object.entries(searchTerms)
      .filter(([key, value]) => (value != null && value !== "") || allValues)
      .map(([key, value]) => key + "=" + value)
      .join("&");
    if (otherQueryParamsPresent) {
      return "&" + query;
    } else {
      return "?" + query;
    }
  }


   getQueryParamWithList(searchTerms, otherQueryParamsPresent) {
      let query = Object.entries(searchTerms)
        .filter(([key, value]) => (value != null && value !== "" && value.length >0))
        .map(([key, value]) => {
            let params;
            if(Array.isArray(searchTerms[key])){
                for(var i=0;i<= searchTerms[key].length-1;i++){
                    if(params != null){
                        params = params + key + "=" + value[i]+ ( searchTerms[key].length -1 != i ? "&":"");
                     } else {
                        params = key + "=" + value[i] +"&";
                     }
                }
            } else {
                    params = key + "=" + value;
            }
            return params;
        }).join("&");
        if (otherQueryParamsPresent) {
              return "&" + query;
            } else {
              return "?" + query;
            }
    }

  getOrder(order) {
    return order === "asc" ? "" : "-";
  }
  sanitizeTerms(terms) {
    for (const [key] of Object.entries(terms)) {
      if (!terms[key] || terms[key].length === 0) {
        terms[key] = "";
      }
      if (Array.isArray(terms[key])) {
        terms[key] = terms[key].toString();
      }
    }
    return terms;
  }

  formatDateForQuery(date) {
    var formatDate = moment(date).utc().startOf("day").format();
    return "[" + formatDate + " TO *]";
  }
}

const searchUtils = new SearchUtils();
export default searchUtils;
