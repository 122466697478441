import {
  ABUSER_MODAL_CLOSE,
  ABUSER_MODAL_OPEN,
  PLAYER_DETAIL_FAILURE,
  PLAYER_DETAIL_REQUEST,
  PLAYER_DETAIL_SUCCESS,
} from "../../../constants";
import {
  getReserveStatusMapping,
  getStatusMapping,
} from "../../player-reducer";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import Translate from "../../../../Layouts/Translator/Translate";
import Moment from "react-moment";

const hiddenIfEmptyList = [
  "title",
  "firstName",
  "middleName",
  "lastName",
  "suffix",
  "citizenship",
  "birthNation",
  "birthLevel1",
  "birthLevel2",
  "birthLevel3",
  "birthLevel4",
  "birthLevel5",
  "domicileCountry",
  "domicileLevel1",
  "domicileLevel2",
  "domicileLevel3",
  "domicileLevel4",
  "domicileLevel5",
  "domicileAddress1",
  "domicileAddress2",
  "domicileAddress3",
  "domicilePostalCode",
  "residenceCountry",
  "residenceLevel1",
  "residenceLevel2",
  "residenceLevel3",
  "residenceLevel4",
  "residenceLevel5",
  "residenceAddress1",
  "residenceAddress2",
  "residenceAddress3",
  "residencePostalCode",
  "billingCountry",
  "billingLevel1",
  "billingLevel2",
  "billingLevel3",
  "billingLevel4",
  "billingLevel5",
  "billingAddress1",
  "billingAddress2",
  "billingAddress3",
  "billingPostalCode",
  "documentType",
  "documentNumber",
  "documentReleasePlace",
  "documentReleaser",
  "documentReleaseDate",
  "documentExpiryDate",
  "phone1",
  "mobilePhone",
  "blackoutEnd",
  "blackoutStart",
  "dateOfBirth",
  "language",
  "heardAboutUs",
  "confirmYouAreOver18Years",
  "promoCode",
];

const getAccountStatusMapping = (status) => {
  let label = "ewlGameAccountstatus_" + status;
  switch (status) {
    case -1: {
      return { id: "13", label: label };
    }
    case 2: {
      return { id: "3", label: label };
    }
    case 1:
    case 6: {
      return { id: "2", label: label };
    }
    case 3:
    case 4: {
      return { id: "7", label: label };
    }
    case 5: {
      return { id: "5", label: label };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const setServiceParam = (data) => {
    if(null == data) return;
    (data.playerServiceParametersGroups || []).forEach((service) => {
    (service.attributes || []).forEach((attribute) => {
      if(serviceParametersGroupsCustom.includes(attribute.attributeId)) {
        data[attribute.attributeId] = attribute.attributeValue;
      }
    });
  });
};

const serviceParametersGroupsCustom = [
  5700,
  5701,
  5702,
  5703,
  5704,
  5705,
  5706,
  5707,
  5707,
  5708,
  5709,
  304,
  300,
  302,
  303,
];

const initialState = {
  data: null,
  abuserModal: null,
  playerDetails: {
    closingAccount: {
      fields: [
        {
          field: "closingStatus",
          title: "label.player.closingAccount.closingStatus",
          render: (rowData) => {
            const statusMapping = getReserveStatusMapping(
              rowData["closingStatus"]
            );
            return <BadgeStatus {...statusMapping} fullWidth />;
          },
        },
        {
          field: "righToBeForgotten",
          title: "label.player.closingAccount.rightToBeForgotten",
          render: (rowData) => (
            <YesNoBadge value={rowData["righToBeForgotten"]} />
          ),
        },
        {
          field: "reserveClosingDate",
          title: "label.player.closingAccount.reserveClosingDate",
          render: (rowData) => (
            <DateFormatter
              format={"L LT"}
              value={rowData["reserveClosingDate"]}
            />
          ),
        },
        {
          field: "closingDate",
          title: "label.player.closingAccount.closingDate",
          render: (rowData) => (
            <DateFormatter format={"L LT"} value={rowData["closingDate"]} />
          ),
        },
        {
          field: "cancelClosingDate",
          title: "label.player.closingAccount.cancelClosingDate",
          render: (rowData) => (
            <DateFormatter
              format={"L LT"}
              value={rowData["cancelClosingDate"]}
            />
          ),
        },
        {
          field: "startedBy",
          title: "label.player.closingAccount.startedBy",
        },
        {
          field: "reason",
          title: "label.player.closingAccount.reason",
        },
      ],
    },
    communicationSettings: {
      fields: [
        {
          field: "receivePromotions",
          title: "label.player.communicationSettings.receivePromotions",
          render: (rowData) => (
            <YesNoBadge value={rowData["receivePromotions"]} />
          ),
        },
        {
          field: "receiveAlerts",
          title: "label.player.communicationSettings.receiveAlerts",
          render: (rowData) => <YesNoBadge value={rowData["receiveAlerts"]} />,
        },
        {
          field: "preferredChannel",
          title: "label.player.communicationSettings.preferredChannel",
        },
      ],
    },
    personalInfo: {
      getFields: (isPam, data, isPoland) => {
        setServiceParam(data);
        const fields = isPam
          ? [
              {
                field: "dateLastPiCheck",
                title: "label.player.personalInfo.dateLastPiCheck",
                render: (rowData) => (
                  <DateFormatter
                    format={"L LT"}
                    value={rowData["dateLastPiCheck"]}
                  />
                ),
              },
              {
                field: "neededToConfirmPi",
                title: "label.player.personalInfo.neededToConfirmPi",
                render: (rowData) => (
                  <YesNoBadge value={rowData.neededToConfirmPi} />
                ),
              },
              {
                field: "reminderUpdatePiSent",
                title: "label.player.personalInfo.reminderUpdatePiSent",
                render: (rowData) => (
                  <YesNoBadge
                    value={rowData.reminderUpdatePiSent + "" !== "0"}
                  />
                ),
              },
              {
                field: "gender",
                title: "label.player.personalInfo.gender",
                lookup: {
                  M: <Translate needle={"label.player.form.4_M"} />,
                  F: <Translate needle={"label.player.form.4_F"} />,
                  U: <Translate needle={"label.player.form.4_U"} />,
                  m: <Translate needle={"label.player.form.4_m"} />,
                  f: <Translate needle={"label.player.form.4_f"} />,
                  u: <Translate needle={"label.player.form.4_u"} />,
                },
              },
              {
                field: "ssnType",
                title: "label.player.personalInfo.ssnType",
                lookup: {
                  0: <Translate needle={"label.player.form.74_0"} />,
                  1: <Translate needle={"label.player.form.74_1"} />,
                },
              },
              { field: "ssn", title: "label.player.personalInfo.ssn" },
              { field: "title", title: "label.player.personalInfo.title" },
              {
                field: "5700",
                title: "label.player.personalInfo.pepState",
                hidden: (rowData) => rowData[5700] == null,
              },
              {
                field: "5701",
                title: "label.player.personalInfo.preAuthDate",
                hidden: (rowData) => rowData[5701] == null,
                render: (rowData) => (
                  <DateFormatter format={"L LT"} value={rowData["5701"]} />
                ),
              },
              {
                field: "5702",
                title: "label.player.personalInfo.preAuthAuthor",
                hidden: (rowData) => rowData[5702] == null,
              },
              {
                field: "5703",
                title: "label.player.personalInfo.authDate",
                hidden: (rowData) => rowData[5703] == null,
                render: (rowData) => (
                  <DateFormatter format={"L LT"} value={rowData["5703"]} />
                ),
              },
              {
                field: "5704",
                title: "label.player.personalInfo.authAuthor",
                hidden: (rowData) => rowData[5704] == null,
              },
              {
                field: "5705",
                title: "label.player.personalInfo.resetDate",
                hidden: (rowData) => rowData[5705] == null,
                render: (rowData) => (
                  <DateFormatter format={"L LT"} value={rowData["5705"]} />
                ),
              },
              {
                field: "5706",
                title: "label.player.personalInfo.resetAuthor",
                hidden: (rowData) => rowData[5706] == null,
              },
              {
                field: "5707",
                title: "label.player.personalInfo.rejectDate",
                hidden: (rowData) => rowData[5707] == null,
                render: (rowData) => (
                  <DateFormatter format={"L LT"} value={rowData["5707"]} />
                ),
              },
              {
                field: "5708",
                title: "label.player.personalInfo.rejectAuthor",
                hidden: (rowData) => rowData[5708] == null,
              },
              {
                field: "304",
                title: "label.player.personalInfo.countryOfBirth",
                hidden: (rowData) => rowData[304] == null,
              },
              {
                field: "300",
                title: "label.player.personalInfo.authenticationSource",
                hidden: (rowData) => rowData[300] == null,
              },
              {
                field: "302",
                title: "label.player.personalInfo.sourceIncome",
                hidden: (rowData) => rowData[302] == null,
                render: (rowData) => {
                  return rowData[302] != null ?  <Translate needle={"label.player.personalInfo.sourceIncomeList."+rowData[302]} label={rowData[302]}/> : null
                }
              },
              {
                field: "303",
                title: "label.player.personalInfo.privateBanking",
                hidden: (rowData) => rowData[303] == null,
                  render: (rowData) => {
                    return rowData[303] ? <YesNoBadge value={rowData[303]}/> : null
                  }
              },
              {
                field: "locationPI",
                title: "label.player.personalInfo.locationPI",
              },
              {
                field: "firstName",
                title: "label.player.personalInfo.firsName",
              },
              {
                field: "middleName",
                title: "label.player.personalInfo.middleName",
              },
              {
                field: "lastName",
                title: "label.player.personalInfo.lastName",
              },
              { field: "suffix", title: "label.player.personalInfo.suffix" },
              {
                field: "citizenship",
                title: "label.player.personalInfo.citizenship",
              },
              {
                field: "birthNation",
                title: "label.player.personalInfo.birthNation",
              },
              {
                field: "birthLevel1",
                title: "label.player.personalInfo.birthLevel1",
              },
              {
                field: "birthLevel2",
                title: "label.player.personalInfo.birthLevel2",
              },
              {
                field: "birthLevel3",
                title: "label.player.personalInfo.birthLevel3",
              },
              {
                field: "birthLevel4",
                title: "label.player.personalInfo.birthLevel4",
              },
              {
                field: "birthLevel5",
                title: "label.player.personalInfo.birthLevel5",
              },
              {
                field: "domicileCountry",
                title: "label.player.personalInfo.domicileCountry",
              },
              {
                field: "domicileLevel1",
                title: "label.player.personalInfo.domicileLevel1",
              },
              {
                field: "domicileLevel2",
                title: "label.player.personalInfo.domicileLevel2",
              },
              {
                field: "domicileLevel3",
                title: "label.player.personalInfo.domicileLevel3",
              },
              {
                field: "domicileLevel4",
                title: "label.player.personalInfo.domicileLevel4",
              },
              {
                field: "domicileLevel5",
                title: "label.player.personalInfo.domicileLevel5",
              },
              {
                field: "domicileAddress1",
                title: "label.player.personalInfo.domicileAddress1",
              },
              {
                field: "domicileAddress2",
                title: "label.player.personalInfo.domicileAddress2",
              },
              {
                field: "domicileAddress3",
                title: "label.player.personalInfo.domicileAddress3",
              },
              {
                field: "domicilePostalCode",
                title: "label.player.personalInfo.domicilePostalCode",
              },
              {
                field: "residenceCountry",
                title: "label.player.personalInfo.residenceCountry",
              },
              {
                field: "residenceLevel1",
                title: "label.player.personalInfo.residenceLevel1",
              },
              {
                field: "residenceLevel2",
                title: `label.player.personalInfo.${
                  isPoland ? "residenceLevel2P" : "residenceLevel2"
                }`,
              },
              {
                field: "residenceLevel3",
                title: "label.player.personalInfo.residenceLevel3",
              },
              {
                field: "residenceLevel4",
                title: "label.player.personalInfo.residenceLevel4",
              },
              {
                field: "residenceLevel5",
                title: "label.player.personalInfo.residenceLevel5",
              },
              {
                field: "residenceAddress1",
                title: `label.player.personalInfo.${
                  isPoland ? "residenceAddress1P" : "residenceAddress1"
                }`,
              },
              {
                field: "residenceAddress2",
                title: `label.player.personalInfo.${
                  isPoland ? "residenceAddress2P" : "residenceAddress2"
                }`,
              },
              {
                field: "residenceAddress3",
                title: `label.player.personalInfo.${
                  isPoland ? "residenceAddress3P" : "residenceAddress3"
                }`,
              },
              {
                field: "residencePostalCode",
                title: "label.player.personalInfo.residencePostalCode",
              },
              {
                field: "billingCountry",
                title: "label.player.personalInfo.billingCountry",
              },
              {
                field: "billingLevel1",
                title: "label.player.personalInfo.billingLevel1",
              },
              {
                field: "billingLevel2",
                title: "label.player.personalInfo.billingLevel2",
              },
              {
                field: "billingLevel3",
                title: "label.player.personalInfo.billingLevel3",
              },
              {
                field: "billingLevel4",
                title: "label.player.personalInfo.billingLevel4",
              },
              {
                field: "billingLevel5",
                title: "label.player.personalInfo.billingLevel5",
              },
              {
                field: "billingAddress1",
                title: "label.player.personalInfo.billingAddress1",
              },
              {
                field: "billingAddress2",
                title: "label.player.personalInfo.billingAddress2",
              },
              {
                field: "billingAddress3",
                title: "label.player.personalInfo.billingAddress3",
              },
              {
                field: "billingPostalCode",
                title: "label.player.personalInfo.billingPostalCode",
              },
              {
                field: "documentType",
                title: "label.player.personalInfo.documentType",
                lookup: {
                  1: <Translate needle={"label.player.form.13_1"} />,
                  2: <Translate needle={"label.player.form.13_2"} />,
                  3: <Translate needle={"label.player.form.13_3"} />,
                  4: <Translate needle={"label.player.form.13_4"} />,
                  5: <Translate needle={"label.player.form.13_5"} />,
                  6: <Translate needle={"label.player.form.13_6"} />,
                },
              },
              {
                field: "documentNumber",
                title: "label.player.personalInfo.documentNumber",
              },
              {
                field: "documentReleasePlace",
                title: "label.player.personalInfo.documentReleasePlace",
              },
              {
                field: "documentReleaser",
                title: "label.player.personalInfo.documentReleaser",
              },
              {
                field: "documentReleaseDate",
                title: "label.player.personalInfo.documentReleaseDate",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    value={rowData["documentReleaseDate"]}
                  />
                ),
              },
              {
                field: "documentExpiryDate",
                title: "label.player.personalInfo.documentExpiryDate",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    value={rowData["documentExpiryDate"]}
                  />
                ),
              },
              {
                field: "phone1",
                title: "label.player.personalInfo.phone1",
              },
              {
                field: "mobilePhone",
                title: "label.player.personalInfo.mobilePhone",
              },
              {
                field: "email",
                title: "label.player.personalInfo.email",
              },
              {
                field: "blackoutEnd",
                title: "label.player.personalInfo.blackoutEnd",
              },
              {
                field: "blackoutStart",
                title: "label.player.personalInfo.blackoutStart",
              },
              {
                field: "dateOfBirth",
                title: "label.player.personalInfo.dateOfBirth",
                render: (rowData) => (
                  <DateFormatter
                    utc
                    format={"L"}
                    value={rowData["dateOfBirth"]}
                  />
                ),
              },
              {
                field: "language",
                title: "label.player.personalInfo.language",
                render: (rowData) =>
                  rowData["language"] ? (
                    <Translate needle={"languages." + rowData["language"]} />
                  ) : null,
              },
              {
                field: "heardAboutUs",
                title: "label.player.personalInfo.heardAboutUs",
              },
              {
                field: "confirmYouAreOver18Years",
                title: "label.player.personalInfo.confirmYouAreOver18Years",
                render: (rowData) => (
                  <YesNoBadge value={rowData["confirmYouAreOver18Years"]} />
                ),
              },
              {
                field: "promoCode",
                title: "label.player.personalInfo.promoCode",
              },
            ]
          : [
              { field: "GENDER", title: "label.player.personalInfo.gender" },
              {
                field: "CURRENCY",
                title: "label.player.personalInfo.currency",
              },
              { field: "SSN", title: "label.player.personalInfo.ssn" },
              {
                field: "LOCATION_PI",
                title: "label.player.personalInfo.locationPI",
              },
              {
                field: "FIRSTNAME",
                title: "label.player.personalInfo.firsName",
              },
              {
                field: "MIDDLENAME",
                title: "label.player.personalInfo.middleName",
              },
              {
                field: "LASTNAME",
                title: "label.player.personalInfo.lastName",
              },
              {
                field: "NATIONALITY",
                title: "label.player.personalInfo.citizenship",
              },
              {
                field: "BIRTH_TOWN",
                title: "label.player.personalInfo.birthTown",
              },
              {
                field: "BIRTH_REGION",
                title: "label.player.personalInfo.birthRegion",
              },
              {
                field: "BIRTH_COUNTRY",
                title: "label.player.personalInfo.birthCountry",
              },
              {
                field: "DOMICILE_TOWN",
                title: "label.player.personalInfo.domicileTown",
              },
              {
                field: "DOMICILE_REGION",
                title: "label.player.personalInfo.domicileRegion",
              },
              {
                field: "DOMICILE_COUNTRY",
                title: "label.player.personalInfo.domicileCountry",
              },
              {
                field: "DOMICILE_ADDRESS",
                title: "label.player.personalInfo.domicileAddress",
              },
              {
                field: "DOMICILE_ZIPCODE",
                title: "label.player.personalInfo.domicilePostalCode",
              },
              {
                field: "RESIDENCE_TOWN",
                title: "label.player.personalInfo.residenceTown",
              },
              {
                field: "RESIDENCE_REGION",
                title: "label.player.personalInfo.residenceRegion",
              },
              {
                field: "RESIDENCE_COUNTRY",
                title: "label.player.personalInfo.residenceCountry",
              },
              {
                field: "ADDRESS_RESIDENCE_1",
                title: "label.player.personalInfo.residenceAddress",
              },
              {
                field: "RESIDENCE_ZIPCODE",
                title: "label.player.personalInfo.residencePostalCode",
              },
              {
                field: "BILLING_TOWN",
                title: "label.player.personalInfo.billingTown",
              },
              {
                field: "BILLING_REGION",
                title: "label.player.personalInfo.billingRegion",
              },
              {
                field: "BILLING_COUNTRY",
                title: "label.player.personalInfo.billingCountry",
              },
              {
                field: "ADDRESS_BILLING_1",
                title: "label.player.personalInfo.billingAddress",
              },
              {
                field: "BILLING_ZIPCODE",
                title: "label.player.personalInfo.billingPostalCode",
              },
              {
                field: "PHONE",
                title: "label.player.personalInfo.phone",
              },
              {
                field: "EMAIL",
                title: "label.player.personalInfo.email",
              },
              {
                field: "BIRTHDATE",
                title: "label.player.personalInfo.dateOfBirth",
                render: (rowData) => (
                  <DateFormatter format={"L"} value={rowData["BIRTHDATE"]} />
                ),
              },
              {
                field: "LANGUAGE",
                title: "label.player.personalInfo.language",
              },
            ];

        return fields.filter(
          (it) =>
            !hiddenIfEmptyList.includes(it.field) ||
            (data[it.field] != null && data[it.field] !== "")
        );
      },
    },
    accountInfo: {
      piFields: ["username", "nickname", "nicknamePAM", "nicknameGNS"],
      getFields: (isPam, isTestPlayer, isSuspended) => {
        return isPam
          ? [
              {
                field: "playerId",
                title: "label.player.playerProfile.playerId",
              },
              {
                field: "brandCode",
                title: "label.player.playerProfile.brandCode",
              },
              {
                field: "partnerCode",
                title: "label.player.playerProfile.partnerCode",
              },
              {
                field: "username",
                title: "label.player.playerProfile.username",
              },
              {
                field: "nickname",
                title: "label.player.playerProfile.nickname",
              },
              {
                field: "contractId",
                title: "label.player.playerProfile.contractId",
              },
              {
                field: "legacyContractId",
                title: "label.player.playerProfile.legacyContractId",
              },
              {
                field: "status",
                title: "label.player.playerProfile.status",
                render: (rowData) => {
                  const statusMapping = getStatusMapping(rowData["status"]);
                  return <BadgeStatus {...statusMapping} fullWidth />;
                },
              },
              {
                field: "testPlayerAccount",
                title: "label.player.playerProfile.accountType",
                render: (rowData) => {
                  if (rowData.testPlayerAccount == "true") {
                    return (
                      <Translate
                        needle={"label.player.playerProfile.testPlayer"}
                      />
                    );
                  }
                },
                hidden: isTestPlayer != "true",
              },
              {
                field: "agencyInfo",
                title: "label.player.playerProfile.agencyInfo",
                hidden: isTestPlayer != "true",
              },
              {
                field: "testPlayerAccountCoversionDate",
                title:
                  "label.player.playerProfile.testPlayerAccountCoversionDate",
                render: (rowData) => (
                  <DateFormatter
                    format={"L LT"}
                    utc
                    value={rowData["testPlayerAccountCoversionDate"]}
                  />
                ),
                hidden: isTestPlayer != "true",
              },
              {
                field: "gameAccountStatus",
                title: "label.player.playerProfile.gameAccountStatus",
                render: (rowData) => {
                  const statusMapping = getAccountStatusMapping(
                    rowData["gameAccountStatus"]
                  );
                  return <BadgeStatus {...statusMapping} fullWidth />;
                },
              },
              {
                field: "suspensionReason",
                title: "label.player.playerProfile.suspensionReason",
                hidden: isSuspended == null,
              },
              {
                field: "startTime",
                title: "label.player.playerProfile.startTime",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    utc
                    value={rowData["startTime"]}
                  />
                ),
              },
              {
                field: "registrationLevelName",
                title: "label.player.playerProfile.registrationLevelName",
              },
              {
                field: "currency",
                title: "label.player.playerProfile.currency",
                render: (rowData) => (
                  <CurrencyBadge currency={rowData["currency"]} />
                ),
              },
              {
                field: "locked",
                title: "label.player.playerProfile.locked",
                render: (rowData) => <YesNoBadge value={rowData.locked} />,
              },
              {
                field: "bonusAbuser",
                title: "label.player.playerProfile.bonusAbuser",
                render: (rowData) => <YesNoBadge value={rowData.bonusAbuser} />,
              },
              {
                field: "ilotteryBlacklisted",
                title: "label.player.playerProfile.ilotteryBlacklisted",
                render: (rowData) => (
                  <YesNoBadge value={rowData.ilotteryBlacklisted} negativeYes />
                ),
              },
            ]
          : [
              { field: "userId", title: "label.player.playerProfile.playerId" },
              {
                field: "brandId",
                title: "label.player.playerProfile.brandCode",
              },
              {
                field: "partnerId",
                title: "label.player.playerProfile.partnerCode",
              },
              {
                field: "nicknamePAM",
                title: "label.player.playerProfile.nicknamePAM",
              },
              {
                field: "nicknameGNS",
                title: "label.player.playerProfile.nicknameGNS",
              },
              {
                field: "referredFriendPlayerId",
                title: "label.player.playerProfile.referredFriendPlayerId",
              },
              {
                field: "referFriendPlayerId",
                title: "label.player.playerProfile.referFriendPlayerId",
              },
              {
                field: "trackingCampaignId",
                title: "label.player.playerProfile.trackingCampaignId",
              },
              {
                field: "playerRegistrationDate",
                title: "label.player.playerProfile.startTime",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    value={rowData["playerRegistrationDate"]}
                  />
                ),
              },
              {
                field: "playerFirstDepositDate",
                title: "label.player.playerProfile.playerFirstDepositDate",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    value={rowData["playerFirstDepositDate"]}
                  />
                ),
              },
              {
                field: "playerFirstWithdrawalDate",
                title: "label.player.playerProfile.playerFirstWithdrawalDate",
                render: (rowData) => (
                  <DateFormatter
                    format={"L"}
                    value={rowData["playerFirstWithdrawalDate"]}
                  />
                ),
              },
            ];
      },
    },
    serviceParameterGroups: {
      columns: [
        {
          field: "id",
          title: "label.individualplayer.serviceParameterGroups.id",
          defaultSort: "asc",
        },
        {
          field: "name",
          title: "label.individualplayer.serviceParameterGroups.name",
        },
        {
          field: "description",
          title: "label.individualplayer.serviceParameterGroups.description",
        },
        {
          field: "creationDate",
          title: "label.individualplayer.serviceParameterGroups.creationDate",
          render: (rowData) => (
            <Moment format={"L LT"}>{rowData.creationDate}</Moment>
          ),
        },
        {
          field: "lastUpdateDate",
          title: "label.individualplayer.serviceParameterGroups.lastUpdateDate",
          render: (rowData) => (
            <Moment format={"L LT"}>{rowData.lastUpdateDate}</Moment>
          ),
        },
      ],
      detailColumns: [
        {
          field: "attributeId",
          title: "label.individualplayer.serviceParameterGroups.attributeId",
          defaultSort: "asc",
        },
        {
          field: "attributeLastUpdateDate",
          title:
            "label.individualplayer.serviceParameterGroups.attributeLastUpdateDate",
          render: (rowData) => (
            <Moment format={"L LT"}>{rowData.attributeLastUpdateDate}</Moment>
          ),
        },
        {
          field: "attributeName",
          title: "label.individualplayer.serviceParameterGroups.attributeName",
        },
        {
          field: "attributeValue",
          title: "label.individualplayer.serviceParameterGroups.attributeValue",
        },
      ],
    },
  },
};
const PlayerDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABUSER_MODAL_OPEN: {
      return {
        ...state,
        abuserModal: {
          action: action.cb,
        },
      };
    }
    case ABUSER_MODAL_CLOSE: {
      return {
        ...state,
        abuserModal: null,
      };
    }
    case PLAYER_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PLAYER_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case PLAYER_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }
    default:
      return state;
  }
};

export default PlayerDetailReducer;
