import axios from "axios";
import { EXPIRE_COOCKIES_ACTION } from "../store/constants";
import { enqueueSnackbar } from "../store/snackbars/actions";

const getGenericError = (httpMethod) => {
  let genericLabel = "";
  switch (httpMethod) {
    case "post":
      genericLabel = "errors.insert_error";
      break;
    case "put":
      genericLabel = "errors.edit_error";
      break;
    case "DELETE":
      genericLabel = "errors.delete_error";
      break;
    default:
      genericLabel = "errors.generic_error";
      break;
  }
  return genericLabel;
};

const labelsToExcludeSnackbar = [
  "warning.campaign.errorCode_29",
  "warning.desk.errorDesk_Approval",
  "warning.campaign.blacklist",
  "contract.not.found",
];

const serviceToExcludeSnackbar = [
  "/gov/api/rest/v1/users/jwt-login",
  "/gov/api/rest/v1/users/silent-login",
];

const isLabelToExclude = (label) => {
  const obj = labelsToExcludeSnackbar.find((l) => l === label);
  return obj != null;
};

const isServiceToExclude = (url) => {
  const obj = serviceToExcludeSnackbar.find((s) => s === url);
  return obj != null;
};

const setupAxiosInterceptors = (store) => {
  const onResponseSuccess = (response) => {
    return response;
  };

  const onResponseFail = (error) => {
    const status = error.status || error.response.status;
    const serviceUrl = error.response.config.url;
    if (status === 401) {
      sessionStorage.clear();
      store.dispatch({
        type: EXPIRE_COOCKIES_ACTION,
        payload: { result: false },
      });
    } else if (status === 400) {
      let label =
        error.response != null && error.response.data != null
          ? error.response.data
          : getGenericError(error.response.config.method);
      
      let variable = [];
      if(typeof label == "string"){
      const labelContainsHashtag = label.toString().indexOf("#") !== -1;
      if (labelContainsHashtag) {
        const labelSplit = label.split("#");
        label = labelSplit[0];
        variable = [labelSplit[1]];
      }
    }
    if(typeof label == "object" && !!label.label ){
      variable = label.attributes
      label= label.label
    }
      if (!isServiceToExclude(serviceUrl) && !isLabelToExclude(label)) {
        const snackbar = enqueueSnackbar({
          message: {
            needle: label,
            variables: variable,
          },
          options: {
            variant: "error",
            autoHideDuration: 8000,
          },
        });
        store.dispatch(snackbar);
      }
    }
    return Promise.reject(error);
  };

  axios.interceptors.response.use(onResponseSuccess, onResponseFail);
  axios.interceptors.request.use(function (config) {
    const accessToken = sessionStorage.getItem("access-token");
    let session = sessionStorage.getItem("session");
    if (accessToken != null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if(session){
      session = JSON.parse(session);
      if(session && session.brandSelected && session.partnerSelected) {
        config.headers['X-BRAND-ID'] = session.brandSelected.id;
        config.headers['X-PARTNER-ID'] = session.partnerSelected.id;
      }
    }
    return config;
  });
};

export default setupAxiosInterceptors;
