import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { useIndividualPlayerLoginHistory } from "../../../../../utilities/hooks/useIndividualPlayerLoginHistory";
import Table from "../../../../../Layouts/Table/Table";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";

function LoginHistory({ widget }) {
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();
  const contractId = match.params.id;
  const { data, columns, loading } = useIndividualPlayerLoginHistory(
    contractId,
    brandSelected.id,
    partnerSelected.id
  );

  const lastData =
    data && data.length > 0
      ? data.reduce((prev, current) =>
          +prev.loginDateTime > +current.loginDateTime ? prev : current
        )
      : null;
  return widget ? (
    <ListPanel loading={loading} data={lastData} fields={columns} />
  ) : (
    <Table
      isLoading={loading}
      title={"label.individualplayer.loginHistory.list"}
      type={"advanced"}
      pagination={true}
      columns={columns}
      data={data}
      exportButton={false}
      pageSize={10}
      page={0}
      orderBy={{ field: "externalSystemDescrption", order: "desc" }}
      actions={[]}
    />
  );
}

export default LoginHistory;
