import axios from "axios";

class LoginHistoryService {
  async loadIndividualPlayerLoginHistory(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v1/contracts/history_login/${contractId}/${brand}/${partner}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for individual player login history");
  }
}

const loginHistoryService = new LoginHistoryService();
export default loginHistoryService;
