import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import financialLimitsService from "./financial-limits-service";
import {
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST,
  FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST,
  FINANCIAL_LIMIT_HISTORY_REQUEST,
  FINANCIAL_LIMIT_HISTORY_SUCCESS,
  FINANCIAL_LIMIT_HISTORY_FAILURE,
} from "../../../../constants";

const getAssociatedProfiles = function* (action) {
  try {
    let data = yield call(
      financialLimitsService.getAssociatedProfiles,
      action.contractId,
      action.brand,
      action.partner
    );
    data = data.map((el) => {
      if(el.alertThreshold == "-1") el.alertThreshold = ""
      if (el.type && el.type.macroType === "CURRENCY") {
        el.value = el.value / action.divisorAmount;
        el.spentLimit = el.spentLimit / action.divisorAmount;
        if (el.reserveProfile && el.reserveProfile.value) {
          el.reserveProfile.value =
            el.reserveProfile.value / action.divisorAmount;
        }
      }
      return el;
    });
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("FinancialLimitsAssociatedProfiles", e);
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE,
    });
  }
};

const editAssociatedProfile = function* (action) {
  try {
    const { contractId, brand, partner, profile, divisorAmount } = action;
    yield call(
      financialLimitsService.editAssociatedProfile,
      action.contractId,
      action.brand,
      action.partner,
      profile
    );
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
      contractId,
      brand,
      partner,
      divisorAmount,
    });
  } catch (e) {
    console.error("FinancialLimitsEditAssociatedProfile", e);
    yield put({
      type: FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_FAILURE,
    });
  }
};

const enableDisableAssociatedProfile = function* (action) {
  try {
    const { contractId, brand, partner, divisorAmount, profile } = action;
    let profileToEdit = { ...profile };
    delete profileToEdit.tableData;
    yield call(
      financialLimitsService.editAssociatedProfile,
      contractId,
      brand,
      partner,
      profileToEdit
    );
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
      contractId,
      brand,
      partner,
      divisorAmount,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            profile.status + "" === "1"
              ? "label.individualplayer.responsibleGame.financialLimits.enabled_successfully"
              : "label.individualplayer.responsibleGame.financialLimits.disabled_successfully",
        },
        options: {
          key: "EnableDisableProfileFinancialLimits",
          variant: "success",
          autoHideDuration: 5000,
        },
      })
    );
  } catch (e) {
    console.error("FinancialLimitsEditAssociatedProfile", e);
    yield put({
      type: FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_FAILURE,
    });
  }
};

const getNotAssociatedProfiles = function* (action) {
  try {
    let data = yield call(
      financialLimitsService.getNotAssociatedProfiles,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("FinancialLimitsAssociatedProfiles", e);
    yield put({
      type: FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE,
    });
  }
};

const saveConfigurationProfiles = function* (action) {
  try {
    const { contractId, brand, partner, divisorAmount } = action;
    let { right } = action;
    if (right) {
      right.forEach((r) => {
        let profile = {};
        profile["value"] = r.defaultValue;
        profile["status"] = -1;
        profile["type"] = r;
        profile["measure"] = r.macroType === "CURRENCY" ? action.currency : "";
        call(
          financialLimitsService.editAssociatedProfile,
          contractId,
          brand,
          partner,
          profile
        );
      });
    }
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
      contractId,
      brand,
      partner,
      divisorAmount,
    });
  } catch (e) {
    console.error("FinancialLimitsAssociatedProfiles", e);
  }
};

const approveRejectProfile = function* (actionSaga) {
  const {
    contractId,
    brand,
    partner,
    divisorAmount,
    profileId,
    action,
    cb,
  } = actionSaga;
  try {
    yield call(
      financialLimitsService.setApproveRejectProfile,
      contractId,
      brand.id,
      partner.id,
      action,
      profileId
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.responsibleGame.financialLimits." +
            action +
            "_succesfully",
        },
        options: {
          key: action + "FinancialLimitProfile",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    cb != null && typeof cb === "function" && cb();
    yield put({
      type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
      contractId,
      brand: brand.id,
      partner: partner.id,
      divisorAmount,
    });
  } catch (e) {
    console.error(action + "FinancialLimitProfile", e);
  }
};

const getFinancialLimitHistory = function* (action) {
  try {
    const data = yield call(
      financialLimitsService.getFinancialLimitHistory,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.profileType
    );

    yield put({
      type: FINANCIAL_LIMIT_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(action + "FinancialLimitHistory", e);
    yield put({
      type: FINANCIAL_LIMIT_HISTORY_FAILURE,
    });
  }
};

const FinancialLimitsSaga = function* financialLimitsSaga() {
  yield takeLatest(
    FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
    getAssociatedProfiles
  );
  yield takeLatest(
    FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST,
    editAssociatedProfile
  );
  yield takeLatest(
    FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST,
    enableDisableAssociatedProfile
  );
  yield takeLatest(
    FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST,
    getNotAssociatedProfiles
  );
  yield takeLatest(
    FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST,
    saveConfigurationProfiles
  );
  yield takeLatest(
    FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST,
    approveRejectProfile
  );
  yield takeLatest(FINANCIAL_LIMIT_HISTORY_REQUEST, getFinancialLimitHistory);
};

export default FinancialLimitsSaga;
