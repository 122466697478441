import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import {
  RAF_DETAIL_SUCCESS,
  RAF_LIST_FAILURE,
  RAF_LIST_SUCCESS,
} from "../../../constants";
import Currency from "../../../../Layouts/Currency/Currency";

const getStatusMapping = (status) => {
  switch (status) {
    case "BONUS_FRIEND_ASSIGNED":
      return { id: "1", label: "friendBonusAssigned" };
    case "FRIEND_REGISTERED":
      return { id: "1", label: "friendRegistered" };
    case "INVITATION_SENT":
      return { id: "3", label: "invitationSent" };
    case "BONUS_REFER_ASSIGNED":
      return { id: "2", label: "bonusReferAssigned" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  rafList: {
    idKey: "friendId",
    loading: true,
    searchCriteria: [
      {
        name: "promoCodeId",
        label: "label.rewards.raf.friends.promoCodeId",
        placeholder: "label.placeholders.rafList.promoCodeId",
      },
      {
        name: "campaignId",
        label: "label.rewards.raf.friends.campaignId",
      },
      {
        name: "referrerGameAccount",
        label: "label.rewards.raf.friends.referrerContractId",
        placeholder: "label.placeholders.playerSearch.contract",
        newLine: true,
      },
      {
        name: "referrerName",
        label: "label.rewards.raf.friends.referralName",
      },
      {
        name: "referrerEmailAddress",
        label: "label.rewards.raf.friends.referrerEmailAddress",
      },
      {
        name: "friendGameAccount",
        label: "label.rewards.raf.friends.friendContractId",
        placeholder: "label.placeholders.playerSearch.contract",
        newLine: true,
      },
      {
        name: "friendName",
        label: "label.rewards.raf.friends.friendName",
      },
      {
        name: "friendEmailAddress",
        label: "label.rewards.raf.friends.friendEmailAddress",
      },
      {
        name: "friendSendMailDate",
        label: "label.rewards.raf.friends.friendSendMailDate",
        type: "daterange",
        placeholder: "label.placeholders.date",
        newLine: true,
      },
      {
        name: "friendRegistrationDate",
        label: "label.rewards.raf.friends.friendRegistrationDate",
        type: "daterange",
        placeholder: "label.placeholders.date",
      },
    ],
    columns: [
      {
        field: "promoCodeId",
        title: "label.rewards.raf.friends.promoCodeId",
      },
      {
        field: "campaignId",
        title: "label.rewards.raf.friends.campaignId",
        default: true,
        defaultSort: "desc",
      },
      {
        field: "refGameAccount",
        title: "label.rewards.raf.friends.refGameAccount",
      },
      {
        field: "friendGameAccount",
        title: "label.rewards.raf.friends.friendGameAccount",
      },
      {
        field: "friendName",
        title: "label.rewards.raf.friends.friendName",
      },
      {
        field: "friendEmail",
        title: "label.rewards.raf.friends.friendEmail",
      },
      {
        field: "status",
        title: "label.rewards.raf.friends.status",
        default: true,
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
                fullWidth
              />
            );
          }
        },
      },
      {
        field: "totalFriendBonusSent",
        title: "label.rewards.raf.friends.totalFriendBonusSent",
        render: (rowData) => {
          if (rowData["totalFriendBonusSent"] != null) {
            return (
              <Currency
                amount={rowData["totalFriendBonusSent"]}
                currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]}
              />
            );
          }
        },
      },
      {
        field: "totalReferBonusSent",
        title: "label.rewards.raf.friends.totalReferBonusSent",
        render: (rowData) => {
          if (rowData["totalReferBonusSent"] != null) {
            return (
              <Currency
                amount={rowData["totalReferBonusSent"]}
                currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]}
              />
            );
          }
        },
      },
    ],
    data: [],
  },
  rafDetails: {
    details: {},
    fields: [
      { field: "friendId", title: "label.rewards.raf.friends.friendId" },
      {
        field: "campaignId",
        title: "label.rewards.raf.friends.campaignId",
      },
      {
        field: "promoCodeId",
        title: "label.rewards.raf.friends.promoCodeId",
      },
      {
        field: "refGameAccount",
        title: "label.rewards.raf.friends.refGameAccount",
      },
      {
        field: "friendGameAccount",
        title: "label.rewards.raf.friends.friendGameAccount",
      },
      {
        field: "totalFriendBonusSent",
        title: "label.rewards.raf.friends.totalFriendBonusSent",
        render: (rowData) => {
          if (rowData["totalFriendBonusSent"] != null) {
            return (
              <Currency
                amount={rowData["totalFriendBonusSent"]}
                currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]}
              />
            );
          }
        },
      },
      {
        field: "totalReferBonusSent",
        title: "label.rewards.raf.friends.totalReferBonusSent",
        render: (rowData) => {
          if (rowData["totalReferBonusSent"] != null) {
            return (
              <Currency
                amount={rowData["totalReferBonusSent"]}
                currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]}
              />
            );
          }
        },
      },
      {
        field: "numberExtraBonusSent",
        title: "label.rewards.raf.friends.numberExtraBonusSent",
      },
      {
        field: "status",
        title: "label.rewards.raf.friends.status",
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
                fullWidth
              />
            );
          }
        },
      },
      {
        field: "friendSendMailDate",
        title: "label.rewards.raf.friends.friendSendMailDate",
        render: (rowData) => {
          if (rowData["friendSendMailDate"]) {
            return (
              <Moment format="L LT">{rowData["friendSendMailDate"]}</Moment>
            );
          }
        },
      },
      {
        field: "friendRegistrationDate",
        title: "label.rewards.raf.friends.friendRegistrationDate",
        render: (rowData) => {
          if (rowData["friendRegistrationDate"]) {
            return (
              <Moment format="L LT">{rowData["friendRegistrationDate"]}</Moment>
            );
          }
        },
      },
      {
        field: "friendName",
        title: "label.rewards.raf.friends.friendName",
      },
      {
        field: "friendEmail",
        title: "label.rewards.raf.friends.friendEmail",
      },
      {
        field: "referrerName",
        title: "label.rewards.raf.friends.referrerName",
      },
      {
        field: "referrerEmail",
        title: "label.rewards.raf.friends.referrerEmail",
      },
      {
        field: "creationDate",
        title: "label.rewards.raf.friends.creationDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
      },
      {
        field: "lastUpdateDate",
        title: "label.rewards.raf.friends.lastUpdateDate",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
      },
    ],
  },
};

const RafListReducer = (state = initialState, action) => {
  switch (action.type) {
    case RAF_LIST_SUCCESS: {
      return {
        ...state,
        rafList: {
          ...state.rafList,
          loading: false,
          data: action.payload,
        },
      };
    }
    case RAF_LIST_FAILURE: {
      return {
        ...state,
        rafList: {
          ...state.rafList,
          loading: false,
          data: [],
        },
      };
    }
    case RAF_DETAIL_SUCCESS: {
      return {
        ...state,
        rafDetails: {
          ...state.rafDetails,
          details: { ...state.rafDetails.details, [action.key]: action.obj },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default RafListReducer;
