import moment from "moment";

const DateFormatter = ({ format = "L", value, defaultValue = "-", utc }) => {
  return value != null
    ? utc
      ? moment.utc(+value || value).format(format)
      : moment(+value || value).format(format)
    : defaultValue;
};

export default DateFormatter;
