import React, { lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadPageActions } from "./store/page-actions/actions";
import menuItemsService from "./utilities/menuItems";
import { useMenuItems } from "./utilities/hooks/useMenuItems";
import { usePageActions } from "./utilities/hooks/usePageActions";
import AuthorizedRoute from "./AuthorizedRoute";

const Home = lazy(() => import("./Pages/Home/Home"));
const DemoRoutes = lazy(() => import("./Pages/Demo/DemoRoutes"));
const OpenWindow = lazy(() => import("./Pages/OpenWindow/OpenWindow"));
const OpenDialog = lazy(() => import("./Pages/OpenDialog/OpenDialog"));
const ExternalUrl = lazy(() => import("./Pages/ExternalUrl/ExternalUrl"));
const PlayerRoute = lazy(() => import("./Pages/Players/PlayerRoute"));
const Desk = lazy(() => import("./Pages/Desk/desk"));
const AdminRoute = lazy(() => import("./Pages/Admin/AdminRoute"));
const RewardsRoute = lazy(() => import("./Pages/rewards/rewards-route"));
const NotificationsRoute = lazy(() =>
  import("./Pages/Notification/notifications-route")
);
const Payment = lazy(() =>
  import("./Pages/Players/individual-player/financial/payment/payment")
);

const CommandRouter = (props) => {
  const location = useLocation();
  const { flatMenuItems } = useMenuItems();
  const { id } = usePageActions();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let item = menuItemsService.findItemByPath(location, flatMenuItems);
    if (item && item.id !== id) {
      dispatch(loadPageActions(item));
    }
  }, [dispatch, location, flatMenuItems, id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path="/" />
      <AuthorizedRoute path="/Home" exact component={Home} />
      <Route path={"/players"} component={PlayerRoute} />
      <Route path={"/admin"} component={AdminRoute} />
      <Route path={"/rewards"} component={RewardsRoute} />
      <AuthorizedRoute path="/desk" exact component={Desk} />
      <Route path="/notifications" component={NotificationsRoute} />
      <AuthorizedRoute path={"/payment"} component={Payment} />
      <AuthorizedRoute
        path="/ExternalUrl/:openUrl"
        exact
        component={ExternalUrl}
        valorizedUrl
      />
      <Route
        path="/OpenWindow/:openUrl"
        component={OpenWindow}
        valorizedUrl
      />
      <AuthorizedRoute
        path="/OpenDialog/:openUrl"
        component={OpenDialog}
        valorizedUrl
      />
      <Route path="*" component={DemoRoutes} />
      <Route path="*" render={() => <Redirect to="/404" />} />
    </Switch>
  );
};

export default CommandRouter;
