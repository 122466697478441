const byString = (o, s) => {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i];
    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }
  return o;
};
export const getDataValue = (rowData, columnDef) => {
  let field =
    rowData[columnDef.field] !== undefined
      ? rowData[columnDef.field]
      : byString(rowData, columnDef.field);
  if (columnDef.lookup) {
    field = columnDef.lookup[field];
  }
  if (field !== undefined) {
    return Array.isArray(field) ? field.join(" ") : field;
  }
  return field;
};

export const getRenderDataValue = (rowData, columnDef, isEditComponent) => {
  let field = getDataValue(rowData, columnDef);
  if (field != null) {
    if (typeof field === "boolean") field = field.toString();
    if (isEditComponent && columnDef.editComponent) {
      columnDef.renderData = columnDef.editComponent;
    }
    return columnDef.renderData ? columnDef.renderData(rowData) : field;
  }

  return columnDef.emptyValue ? columnDef.emptyValue : field;
};
