import {
  AUTHENTICATION_REQUEST,
  EXTERNAL_URL_REFRESH_KEY_ACTION,
  LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST,
  LOGOUT_REQUEST,
  PW_RESET_REQUEST,
  PWCHANGE_REQUEST,
  PWRECOVERY_REQUEST,
  REFRESH_TOKEN_REQUEST,
  SENDMAIL_REQUEST,
  SESSION_INFO_REQUEST,
  UPDATE_BRAND_PARTNER_ACTION,
  SENDMAIL_RESET
} from "../constants";

export const sessionInfoHandler = () => {
  return {
    type: SESSION_INFO_REQUEST,
  };
};

export const authenticatedHandler = (
  username,
  password,
  externalLoginPlugin,
  brandLogin,
  partnerLogin
) => {
  return {
    type: AUTHENTICATION_REQUEST,
    payload: {
      username,
      password,
      externalLoginPlugin,
      brandLogin,
      partnerLogin,
    },
  };
};

export const logoutHandler = (ipAddress, window) => {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      ipAddress,
      window,
    },
  };
};

export const pwExpiredHandler = (
  currentPassword,
  newPassword,
  confirmNewPassword,
  username,
  cb
) => {
  return {
    type: PW_RESET_REQUEST,
    currentPassword,
    newPassword,
    confirmNewPassword,
    username,
    cb,
  };
};

export const sendMailHandler = (username) => {
  return {
    type: SENDMAIL_REQUEST,
    payload: {
      username,
    },
  };
};

export const sendMailReset = () => {
  return {
    type: SENDMAIL_RESET,
    payload: {},
  };
};

export const pwRecoveryHandler = (newPassword, confirmNewPassword, token) => {
  return {
    type: PWRECOVERY_REQUEST,
    payload: {
      newPassword,
      confirmNewPassword,
      token,
    },
  };
};

export const pwChangeHandler = (
  oldPassword,
  newPassword,
  confirmnewPassword,
  cb
) => {
  return {
    type: PWCHANGE_REQUEST,
    payload: {
      oldPassword,
      newPassword,
      confirmnewPassword,
    },
    cb,
  };
};

export const updateBrandPartnerHandler = (brand, partner) => {
  return {
    type: UPDATE_BRAND_PARTNER_ACTION,
    payload: {
      brandSelected: brand,
      partnerSelected: partner,
    },
  };
};

export const externalUrlRefreshKeyHandler = () => {
  return {
    type: EXTERNAL_URL_REFRESH_KEY_ACTION,
  };
};

export const getExternalLoginPlugins = () => {
  return {
    type: LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST,
  };
};

export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
};
