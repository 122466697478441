import Box from "@material-ui/core/Box";
import * as moment from "moment";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { FormattedNumber } from "react-intl";
import Moment from "react-moment";
import BadgeStatus from "../../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../../Layouts/Currency/Currency";
import {
  getMaxLengthValidation,
  getMinLengthValidation,
  getPatternValidation,
  getRequiredValidation,
  getValidationByType,
} from "../../../../../Layouts/form/utils";
import AmountProgressBar from "../../../../../Layouts/progress-bar/amount-progress-bar";
import Translate from "../../../../../Layouts/Translator/Translate";
import translateService from "../../../../../utilities/translate";
import {
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS,
  FINANCIAL_LIMIT_HISTORY_FAILURE,
  FINANCIAL_LIMIT_HISTORY_SUCCESS,
} from "../../../../constants";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case 1:
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "enabled";
      break;
    case -1:
      badgeMapped.badgeId = "5";
      badgeMapped.badgeLabel = "disabled";
      break;
    default:
  }
  return badgeMapped;
};

const initialState = {
  loading: true,
  notAssociatedProfileList: [],
  associatedProfileList: {
    data: [],
    columns: (divisorAmount) => {
      return [
        {
          field: "type.profileDataTypeId",
          title:
            "label.individualplayer.responsibleGame.financialLimits.profileName",
          default: true,
          defaultSort: "asc",
          widget: true,
          editable: false,
          customSort: (rowData1, rowData2) => {
            return rowData1?.["type"]?.["profileDataTypeId"] &&
              rowData2?.["type"]?.["profileDataTypeId"]
              ? translateService
                  .translateIt(
                    "label.individualplayer.responsibleGame.financialLimits.profile." +
                      rowData1["type"]["profileDataTypeId"]
                  )
                  .toString()
                  .toUpperCase()
                  .localeCompare(
                    translateService
                      .translateIt(
                        "label.individualplayer.responsibleGame.financialLimits.profile." +
                          rowData2["type"]["profileDataTypeId"]
                      )
                      .toString()
                      .toUpperCase()
                  )
              : 0;
          },
          customFilterAndSearch: (term, rowData) => {
            return translateService
              .translateIt(
                "label.individualplayer.responsibleGame.financialLimits.profile." +
                  rowData["type"]["profileDataTypeId"]
              )
              .toString()
              .toUpperCase()
              .includes(term.toUpperCase());
          },
          render: (rowData) => {
            return (
              <Translate
                needle={
                  "label.individualplayer.responsibleGame.financialLimits.profile." +
                  rowData["type"]["profileDataTypeId"]
                }
              />
            );
          },
        },
        {
          field: "value",
          title: "label.individualplayer.responsibleGame.financialLimits.value",
          hidden: isMobileOnly,
          widget: true,
          nowrap: false,
          editComponentDef: (row) => {
            return {
              name: "value",
              labelPrefix:
                "label.individualplayer.responsibleGame.financialLimits.",
              type: "Integer",
              validation:
                row.type.maxValue !== null &&
                getMinLengthValidation(
                  getMaxLengthValidation(
                    getRequiredValidation(
                      getValidationByType("Integer"),
                      "Integer",
                      "label.individualplayer.responsibleGame.financialLimits.",
                      "value"
                    ),
                    row.type.maxValue / divisorAmount
                  ),
                  row.type.minValue / divisorAmount
                ),
            };
          },
          render: (rowData) => {
            if (rowData["value"]) {
              const spentLimit = rowData["spentLimit"]
                ? rowData["spentLimit"]
                : 0;
              const value = rowData["value"];

              if (rowData["type"]["macroType"] === "THRESHOLD") {
                return <Moment format="L LT">{+rowData["value"]}</Moment>;
              } else {
                return (
                  <AmountProgressBar
                    value={spentLimit}
                    limit={value}
                    applyDivisorAmount={false}
                    currency={rowData["measure"] ? rowData["measure"] : ""}
                  />
                );
              }
            }
          },
        },
        {
          field: "alertThreshold",
          title: "label.individualplayer.responsibleGame.playerLimits.tableColumns.limitThreshold",
          nowrap: false,
          editComponentDef: (row) => {
            return {
              name: "alertThreshold",
              labelPrefix: "label.individualplayer.responsibleGame.playerLimits.tableColumns.",
              type: "Text",
              validation: getPatternValidation(
                getValidationByType("Text"),
                "^[1-9]{1}[0-9]?$|^$",
                "label.individualplayer.responsibleGame.playerLimits.tableColumns.",
                "limitThreshold",
                <Translate
                  needle={
                    "label.individualplayer.responsibleGame.playerLimits.message.limitThreshold.invalidLimitThreshold"
                  }
                ></Translate>
              ),
            };
          },
          render: (rowData, column) => {
            if (rowData["alertThreshold"] == "") return <>-</>;
            return <>{rowData["alertThreshold"]}%</>;
          },
        },
        {
          field: "status",
          title:
            "label.individualplayer.responsibleGame.financialLimits.status",
          hidden: !isMobileOnly,
          editable: false,
          default: true,
          widget: true,
          render: (rowData) => {
            if (rowData["status"]) {
              const badgeMapped = getStatusMapping(rowData["status"]);
              return (
                <BadgeStatus
                  id={badgeMapped.badgeId}
                  label={badgeMapped.badgeLabel}
                  justify="center"
                />
              );
            }
          },
        },
        {
          field: "spentLimit",
          title:
            "label.individualplayer.responsibleGame.financialLimits.spentLimit",
          hidden: !isMobileOnly,
          editable: false,
          widget: true,
          render: (rowData) => {
            const spentLimit = rowData["spentLimit"]
              ? rowData["spentLimit"]
              : 0;
            if (rowData["measure"]) {
              return (
                <Currency
                  amount={spentLimit}
                  currency={rowData["measure"]}
                  applyDivisorAmount={false}
                />
              );
            } else {
              return <FormattedNumber value={spentLimit} />;
            }
          },
        },
        {
          field: "value",
          title:
            "label.individualplayer.responsibleGame.financialLimits.maxValue",
          hidden: !isMobileOnly,
          widget: true,
          render: (rowData) => {
            if (rowData["measure"]) {
              return (
                <Currency
                  amount={rowData["value"]}
                  currency={rowData["measure"]}
                  applyDivisorAmount={false}
                />
              );
            } else {
              return <FormattedNumber value={rowData["value"]} />;
            }
          },
        },
        {
          field: "updateDate",
          widget: isMobileOnly,
          title:
            "label.individualplayer.responsibleGame.financialLimits.updateDate",
          default: true,
          editable: false,
          render: (rowData) => {
            if (rowData["updateDate"]) {
              return <Moment format="L LT">{rowData["updateDate"]}</Moment>;
            }
          },
        },
        {
          field: "type.reserveProfile",
          title:
            "label.individualplayer.responsibleGame.financialLimits.reserveProfile",
          editable: false,
          widget: isMobileOnly,
          nowrap: false,
          render: (rowData) => {
            const measure = rowData["measure"];
            if (rowData["reserveStatus"] === 1) {
              return (
                <Box>
                  {measure ? (
                    <Currency
                      amount={rowData["reserveValue"]}
                      currency={measure}
                      applyDivisorAmount={true}
                      justify={"flex-end"}
                    />
                  ) : (
                    <FormattedNumber value={rowData["reserveValue"]} />
                  )}
                  <Translate
                    needle={
                      "label.individualplayer.responsibleGame.financialLimits.messageAvailability"
                    }
                    variables={[
                      moment(rowData["coolingOffDate"]).format("L LT"),
                    ]}
                  />
                </Box>
              );
            } else if (rowData["reserveStatus"] === 3) {
              return (
                <Box>
                  {measure ? (
                    <Currency
                      amount={rowData["reserveValue"]}
                      currency={measure}
                      applyDivisorAmount={false}
                      justify={"flex-end"}
                    />
                  ) : (
                    <FormattedNumber value={rowData["reserveValue"]} />
                  )}
                  <Translate
                    needle={
                      "label.individualplayer.responsibleGame.financialLimits.messageExpiration"
                    }
                    variables={[
                      moment(rowData["coolingOffDate"]).format("L LT"),
                    ]}
                  />
                </Box>
              );
            } else {
              return (
                <Translate
                  needle={
                    "label.individualplayer.responsibleGame.financialLimits.noReserve"
                  }
                />
              );
            }
          },
        },
      ].filter((it) => !it.hidden);
    },
  },
  financialLimitHistory: {
    loading: true,
    history: [],
    columns: (currency) => {
      return [
        {
          field: "transactionDate",
          title:
            "label.individualplayer.responsibleGame.financialLimits.history.transactionDate",
          render: (rowData) => {
            if (rowData["transactionDate"]) {
              return (
                <Moment format="L LT">{rowData["transactionDate"]}</Moment>
              );
            }
          },
        },
        {
          field: "previousValue",
          title:
            "label.individualplayer.responsibleGame.financialLimits.history.previousValue",
          render: (rowData) => {
            const previousValue = rowData["previousValue"]
              ? rowData["previousValue"]
              : 0;
            if (currency) {
              return (
                <Currency
                  amount={previousValue}
                  currency={currency}
                  applyDivisorAmount={true}
                />
              );
            } else {
              return <FormattedNumber value={previousValue} />;
            }
          },
        },
        {
          field: "changeSource",
          title:
            "label.individualplayer.responsibleGame.financialLimits.history.changeSource",
        },
      ];
    },
  },
};

const FinancialLimitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        associatedProfileList: {
          ...state.associatedProfileList,
          data: action.payload,
        },
      };
    }
    case FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE: {
      return {
        ...state,
        loading: false,
        associatedProfileList: { ...state.associatedProfileList, data: null },
      };
    }
    case FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS: {
      return {
        ...state,
        notAssociatedProfileList: action.payload,
      };
    }
    case FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE: {
      return {
        ...state,
        notAssociatedProfileList: null,
      };
    }
    case FINANCIAL_LIMIT_HISTORY_SUCCESS: {
      return {
        ...state,
        financialLimitHistory: {
          ...state.financialLimitHistory,
          loading: false,
          history: action.payload,
        },
      };
    }
    case FINANCIAL_LIMIT_HISTORY_FAILURE: {
      return {
        ...state,
        financialLimitHistory: {
          ...state.financialLimitHistory,
          loading: false,
          history: null,
        },
      };
    }
    default:
      return state;
  }
};

export default FinancialLimitsReducer;
