import React from "react";

import Moment from "react-moment";
import * as moment from "moment";
import {
  COMMUNICATION_HISTORY_SUCCESS,
  COMMUNICATION_HISTORY_FAILURE,
  COMMUNICATION_HISTORY_DETAILS_SUCCESS,
  COMMUNICATION_HISTORY_DETAILS_FAILURE,
  COMMUNICATION_HISTORY_REQUEST,
} from "../../../constants";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";

const initialState = {
  loading: true,
  communications: {
    data: [],
    widgetColumns: [
      {
        field: "subject",
        title: "label.individualCommunicationHistory.subject",
        default: true,
      },
      {
        field: "lastUpdated",
        title: "label.individualCommunicationHistory.sendDate",
        default: true,
        render: (rowData) => {
          if (rowData["lastUpdated"]) {
            return <Moment format="L LT">{rowData["lastUpdated"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["lastUpdated"]) {
            return moment(rowData["lastUpdated"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "campaignId",
        title: "label.individualCommunicationHistory.campaign",
      },
    ],
    columns: [
      {
        field: "channelName",
        title: "label.individualCommunicationHistory.channelName",
        default: true,
      },
      {
        field: "fromValue",
        title: "label.individualCommunicationHistory.fromValue",
      },
      {
        field: "subject",
        title: "label.individualCommunicationHistory.subject",
        default: true,
      },
      {
        field: "lastUpdated",
        title: "label.individualCommunicationHistory.lastUpdated",
        render: (rowData) => {
          if (rowData["lastUpdated"]) {
            return <Moment format="L LT">{rowData["lastUpdated"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["lastUpdated"]) {
            return moment(rowData["lastUpdated"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "contactType.contactTypeName",
        title: "label.individualCommunicationHistory.contactType",
      },
      {
        field: "gmsDlvTemplate.templateName",
        title: "label.individualCommunicationHistory.gmsDlvTemplate",
      },
      {
        field: "lastAttempt",
        title: "label.individualCommunicationHistory.lastAttempt",
        render: (rowData) => {
          if (rowData["lastAttempt"]) {
            return <Moment format="L LT">{rowData["lastAttempt"]}</Moment>;
          }
        },
      },
      {
        field: "firstAttempt",
        title: "label.individualCommunicationHistory.creationDate",
        render: (rowData) => {
          if (rowData["firstAttempt"]) {
            return <Moment format="L LT">{rowData["firstAttempt"]}</Moment>;
          }
        },
      },
      {
        field: "readed",
        title: "label.individualCommunicationHistory.readed",
        render: (rowData) => (
          <YesNoBadge value={rowData["readed"] !== 0} justify={"center"} />
        ),
      },
    ],
  },
  details: null,
};

const CommunicationHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMUNICATION_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case COMMUNICATION_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        communications: { ...state.communications, data: action.payload },
      };
    }
    case COMMUNICATION_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        communications: { ...state.communications, data: null },
      };
    }
    case COMMUNICATION_HISTORY_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload,
      };
    }
    case COMMUNICATION_HISTORY_DETAILS_FAILURE: {
      return {
        ...state,
        details: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default CommunicationHistoryReducer;
