import React from "react";
import { useSession } from "../../../../utilities/hooks/useSession";
import { useSelector, useDispatch } from "react-redux";
import { getPlayerDetail } from "../../../../store/players/individual-player/player-detail/actions";
import Table from "../../../../Layouts/Table/Table";
import { labelPrefix } from "../../../../store/players/individual-player/self-exclusion/self-exclusion-reducer";
import { isActionHidden } from "../../../../utils";
import { usePageActions } from "../../../../utilities/hooks/usePageActions";
import {
  loadSelfExclusion,
  updateSelfExclusion,
} from "../../../../store/players/individual-player/self-exclusion/actions";
import { useRouteMatch } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { History, AddCircle } from "@material-ui/icons";
import Modal from "../../../../Layouts/Modal/Modal";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import SelfExclusionHistory from "./self-exclusion-history";
import AddSelfExclusion from "./add-self-exclusion";

function SelfExclusion() {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();
  const match = useRouteMatch();
  const playerId = match.params.id;
  const { isLoading, data, columns } = useSelector(
    (state) => state.SelfExclusionReducer.selfExclusion
  );
  const [updateSwitch, setUpdateSwitch] = React.useState(false);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [historyModal, setHistoryModal] = React.useState(null);
  const { buttons } = usePageActions();
  const [
    removeSelfExclusionConfirmModal,
    setRemoveSelfExclusionConfirmModal,
  ] = React.useState(false);

  React.useEffect(() => {
    if (updateSwitch) {
      setUpdateSwitch(false);
      dispatch(
        getPlayerDetail(
          playerId,
          brandSelected.id,
          partnerSelected.id,
          partnerSelected.pam
        )
      );
    }
  }, [updateSwitch]);

  React.useEffect(() => {
    if (brandSelected && partnerSelected) {
      dispatch(loadSelfExclusion(brandSelected, partnerSelected, playerId));
    }
  }, [brandSelected, partnerSelected, playerId]);

  const handleOpenAddSelfExclusionModal = () => {
    setOpenAddModal(!openAddModal);
  };

  const handleCloseRemoveSelfExclusionModal = () => {
    setRemoveSelfExclusionConfirmModal(!removeSelfExclusionConfirmModal);
  };

  const handleOpenHistoryModal = (rowData) => {
    setHistoryModal(rowData);
  };

  const handleRemoveSelfExclusion = () => {
    const body = { playerSelfExcluded: false };
    dispatch(
      updateSelfExclusion(
        brandSelected,
        partnerSelected,
        playerId,
        body,
        () => {
          dispatch(loadSelfExclusion());
        }
      )
    );
    handleCloseRemoveSelfExclusionModal();
    setUpdateSwitch(true);
  };

  return (
    <Box>
      <Table
        isLoading={isLoading}
        pagination={false}
        type={"advanced"}
        resize={false}
        title={`${labelPrefix}selfExclusionTable`}
        columns={columns}
        data={[data]}
        actions={[
          (rowData) => ({
            icon: AddCircle,
            color: "success",
            variant: "contained",
            tooltip: "button.addSelfExclusion",
            hidden:
              rowData["playerIsSelfExcluded"] ||
              isActionHidden(buttons, "add_self_exclusion"),
            onClick: (event, rowData) => {
              handleOpenAddSelfExclusionModal(rowData);
            },
          }),
          (rowData) => ({
            color: "primary",
            variant: "contained",
            tooltip: "button.removeSelfExclusion",
            hidden:
              !rowData["playerIsSelfExcluded"] ||
              isActionHidden(buttons, "remove_self_exclusion"),
            onClick: (event, rowData) => {
              setRemoveSelfExclusionConfirmModal(rowData);
            },
          }),
          () => ({
            icon: History,
            tooltip: "button.history",
            color: "primary",
            hidden: isActionHidden(buttons, "self_exclusion_history"),
            onClick: (e, rowData) => {
              handleOpenHistoryModal(rowData);
            },
          }),
        ]}
      />
      <Modal
        open={openAddModal}
        title={t({ needle: `${labelPrefix}addSelfExclusion` })}
        content={
          <AddSelfExclusion cancelCb={handleOpenAddSelfExclusionModal} />
        }
      />
      <Modal
        open={Boolean(historyModal)}
        content={<SelfExclusionHistory data={historyModal?.data} />}
        handleClose={() => setHistoryModal(null)}
        maxWidth="lg"
      />
      <Modal
        open={Boolean(removeSelfExclusionConfirmModal)}
        title={t({
          needle: "label.modal.confirmDeletion",
        })}
        description={t({
          needle: `${labelPrefix}removeSelfExclusionWarn`,
          avoidLowerCase: true,
        })}
        actions={[
          {
            color: "primary",
            label: "button.cancel",
            variant: "outlined",
            handler: handleCloseRemoveSelfExclusionModal,
          },
          {
            color: "failure",
            label: "button.remove",
            handler: handleRemoveSelfExclusion,
          },
        ]}
      />
    </Box>
  );
}

export default SelfExclusion;
