import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import {
  LOAD_ROLES_FAILURE,
  LOAD_ROLES_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  PARSE_MULTIPLE_FAILURE,
  PARSE_MULTIPLE_SUCCESS,
  USER_DETAIL_FAILURE,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.admin.security.users.";

const initialState = {
  users: {
    isLoading: true,
    data: [],
    columns: [
      {
        field: "id",
        title: labelPrefix + "id",
        default: true,
        defaultSort: "asc",
      },
      {
        field: "username",
        title: labelPrefix + "username",
        default: true,
      },
      {
        field: "firstName",
        title: labelPrefix + "firstName",
      },
      {
        field: "lastName",
        title: labelPrefix + "lastName",
      },
      {
        field: "email",
        title: labelPrefix + "email",
      },
      {
        field: "role.description",
        title: labelPrefix + "role",
      },
      {
        field: "locked",
        title: labelPrefix + "locked",
        render: (rowData) => {
          return <YesNoBadge value={rowData["locked"]} justify={"center"} />;
        },
      },
    ],
  },
  userDetail: {
    isLoading: true,
    fields: [
      {
        field: "username",
        title: labelPrefix + "username",
        default: true,
      },
      {
        field: "firstName",
        title: labelPrefix + "firstName",
        default: true,
      },
      {
        field: "middleName",
        title: labelPrefix + "middleName",
        default: true,
      },
      {
        field: "lastName",
        title: labelPrefix + "lastName",
        default: true,
      },
      {
        field: "email",
        title: labelPrefix + "email",
        default: true,
      },

      {
        field: "language.languageName",
        title: labelPrefix + "language",
        default: true,
      },
      {
        field: "locked",
        title: labelPrefix + "locked",
        default: true,
        render: (rowData) => {
          return <YesNoBadge value={rowData["locked"]} />;
        },
      },
      {
        field: "lastLogin",
        title: labelPrefix + "lastLogin",
        default: true,
        render: (rowData) => {
          if (rowData["lastLogin"]) {
            return <Moment format="L LT">{rowData["lastLogin"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["lastLogin"]) {
            return moment(rowData["lastLogin"]).format("L LT");
          }
        },
      },
      {
        field: "lockedDate",
        title: labelPrefix + "lockedDate",
        default: true,
        render: (rowData) => {
          if (rowData["lockedDate"]) {
            return <Moment format="L LT">{rowData["lockedDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["lockedDate"]) {
            return moment(rowData["lockedDate"]).format("L LT");
          }
        },
      },
      {
        field: "creationDate",
        title: labelPrefix + "creationDate",
        default: true,
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["creationDate"]) {
            return moment(rowData["creationDate"]).format("L LT");
          }
        },
      },
      {
        field: "passwordCreation",
        title: labelPrefix + "passwordCreation",
        default: true,
        render: (rowData) => {
          if (rowData["passwordCreation"]) {
            return <Moment format="L LT">{rowData["passwordCreation"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["passwordCreation"]) {
            return moment(rowData["passwordCreation"]).format("L LT");
          }
        },
      },
      {
        field: "role.description",
        title: labelPrefix + "role",
        default: true,
      },
      {
        field: "consentAccepted",
        title: labelPrefix + "consentAccepted",
        default: true,
        render: (rowData) => {
          return <YesNoBadge value={rowData["consentAccepted"]} />;
        },
      },
      {
        field: "consentEditedBy",
        title: labelPrefix + "consentEditedBy",
        default: true,
      },
    ],
    data: null,
  },
  roles: [],
  parsedData: [],
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          lastRequest: action,
        },
      };

    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload,
          isLoading: false,
        },
      };

    case LOAD_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          data: null,
          isLoading: false,
        },
      };
    case USER_DETAIL_REQUEST:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: null,
          isLoading: true,
        },
      };
    case USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: action.payload,
          isLoading: false,
        },
      };

    case USER_DETAIL_FAILURE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: null,
          isLoading: false,
        },
      };

    case LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case LOAD_ROLES_FAILURE:
      return {
        ...state,
        roles: [],
      };

    case PARSE_MULTIPLE_SUCCESS:
      return {
        ...state,
        parsedData: action.payload,
      };

    case PARSE_MULTIPLE_FAILURE:
      return {
        ...state,
        parsedData: [],
      };

    default:
      return {
        ...state,
      };
  }
};

export default UsersReducer;
