import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  ADD_EDIT_TO_BLOCKLIST_FAILURE,
  ADD_EDIT_TO_BLOCKLIST_REQUEST,
  ADD_EDIT_TO_BLOCKLIST_SUCCESS,
  LOTTERY_BLOCKLIST_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_REQUEST,
  LOTTERY_BLOCKLIST_FILTER_SUCCESS,
  LOTTERY_BLOCKLIST_REQUEST,
  LOTTERY_BLOCKLIST_SUCCESS,
  REMOVE_FROM_BLOCKLIST_FAILURE,
  REMOVE_FROM_BLOCKLIST_REQUEST,
  REMOVE_FROM_BLOCKLIST_SUCCESS,
} from "../../../constants";
import lotteryBlocklistService from "./lottery-blocklist-service";
import { enqueueSnackbar } from "../../../snackbars/actions";
import { labelPrefix } from "./lottery-blocklist-reducer";

const loadListFilter = function* (action) {
  try {
    const data = yield call(
      lotteryBlocklistService.getListFilter,
      action.brand,
      action.partner
    );
    yield put({
      type: LOTTERY_BLOCKLIST_FILTER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("lottery block list filters error", e);
    yield put({
      type: LOTTERY_BLOCKLIST_FILTER_FAILURE,
    });
  }
};

const loadLotteryBlocklist = function* (action) {
  try {
    const data = yield call(
      lotteryBlocklistService.loadLotteryBlocklist,
      action.query,
      action.searchTerms,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: LOTTERY_BLOCKLIST_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("lottery blocklist load", e);
    yield put({
      type: LOTTERY_BLOCKLIST_FAILURE,
    });
  }
};

const addEditToBlocklist = function* (action) {
  try {
    const data = yield call(
      lotteryBlocklistService.addEditToBlocklist,
      action.brandId,
      action.partnerId,
      action.values,
      action.addType
    );

    yield put(
      enqueueSnackbar({
        message: {
          needle: action.isEdit
            ? `${labelPrefix}editBlocklistSuccess`
            : `${labelPrefix}addToBlocklistSuccess`,
        },
        options: {
          key: "addEditToBlocklist",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );

    yield put({
      type: ADD_EDIT_TO_BLOCKLIST_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("add/edit lottery blocklist", e);
    yield put({
      type: ADD_EDIT_TO_BLOCKLIST_FAILURE,
    });
  }
};

const removeFromBlocklist = function* (action) {
  try {
    const data = yield call(
      lotteryBlocklistService.removeFromBlocklist,
      action.brandId,
      action.partnerId,
      action.userBlacklistId
    );

    yield put(
      enqueueSnackbar({
        message: {
          needle: `${labelPrefix}removeFromBlocklistSuccess`,
        },
        options: {
          key: "addEditToBlocklist",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );

    yield put({
      type: REMOVE_FROM_BLOCKLIST_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("remove lottery blocklist", e);
    yield put({
      type: REMOVE_FROM_BLOCKLIST_FAILURE,
    });
  }
};

const LotteryBlocklistSaga = function* LotteryBlocklistSaga() {
  yield takeLatest(LOTTERY_BLOCKLIST_REQUEST, loadLotteryBlocklist);
  yield takeLatest(LOTTERY_BLOCKLIST_FILTER_REQUEST, loadListFilter);
  yield takeLatest(ADD_EDIT_TO_BLOCKLIST_REQUEST, addEditToBlocklist);
  yield takeLatest(REMOVE_FROM_BLOCKLIST_REQUEST, removeFromBlocklist);
};

export default LotteryBlocklistSaga;
