import { Box, Switch } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useField } from "formik";
import React from "react";
import FormLabel from "./form-label";

function SwitchField({
  isConditional = false,
  optional,
  errorText,
  label,
  tooltip,
  fullWidth = false,
  size,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { value } = field;

  function _renderHelperText() {
    const { error } = meta;

    if (error) {
      return error;
    }
  }

  return (
    <FormControl fullWidth={fullWidth} error={meta.error != null}>
      <Box>
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={Boolean(value)}
              size={size || "small"}
              onChange={(event) => {
                helpers.setValue(event.target.checked);
              }}
              {...props}
            />
          }
          label={
            <FormLabel optional={optional} label={label} tooltip={tooltip} />
          }
        />
      </Box>
      {!isConditional ? (
        <FormHelperText>{_renderHelperText()}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default SwitchField;
