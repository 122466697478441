import React from "react";
import Moment from "react-moment";
import Translate from "../../../../Layouts/Translator/Translate";

export const labelPrefix = "label.individualplayer.blocklist.";
const initialState = {
  loading: true,
  blocklist: {
    data: [],
    columns: [
      {
        field: "blockType",
        title: `${labelPrefix}operationType`,
        render: (rowData) => {
          if (rowData["blockType"] != null) {
            let blockType = `${labelPrefix}blockTypes.` + rowData["blockType"];
            return <Translate needle={blockType} />;
          }
        },
        default: true,
      },
      {
        field: "executionDate",
        title: `${labelPrefix}executionDate`,
        render: (rowData) => {
          if (rowData["executionDate"]) {
            return <Moment format="L LT">{rowData["executionDate"]}</Moment>;
          }
        },
      },
      {
        field: "doneBy",
        title: `${labelPrefix}doneBy`,
      },
      {
        field: "note",
        title: `${labelPrefix}note`,
      },
    ],
  },
};

const BlocklistReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default BlocklistReducer;
