import { getRenderDataValue } from "./utils";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const TableCell = ({ rowData, columnDef }) => {
  const ref = React.useRef();
  const [title, setTitle] = React.useState("");
  const [dataValue, setDataValue] = React.useState();

  React.useEffect(() => {
    setDataValue(getRenderDataValue(rowData, columnDef));
  }, [rowData, columnDef]);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setTitle(dataValue);
    }
  }, [dataValue]);

  return (
    <Tooltip title={title}>
      <div
        style={{
          textOverflow: "ellipsis",
          whiteSpace: columnDef.nowrap === false ? "normal" : "nowrap",
          width: "100%",
          overflow: "hidden",
          maxWidth:
            columnDef.nowrap === false
              ? "auto"
              : ref?.current?.parentNode.offsetWidth,
          wordBreak: "break-all",
        }}
        ref={ref}
      >
        {dataValue}
      </div>
    </Tooltip>
  );
};

export default TableCell;
