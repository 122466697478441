import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import {
  CRITERIA_TYPE_SELECT,
  CRITERIA_TYPE_DATERANGE,
} from "../../../constants";

class BulkOperationListService {
  async getBulkOperationListSearchCriteria(brand, partner) {
    const filter = [
      {
        name: "massiveId",
        label: "label.massiveRewards.requestId",
        selected: true,
      },
      {
        name: "requestName",
        label: "label.massiveRewards.requestName",
      },
      {
        name: "playerGroupName",
        label: "label.massiveRewards.groupName",
      },
      {
        name: "status",
        label: "label.massiveRewards.requestStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: [
          { label: "label.status.completed", value: "COMPLETED_SUCCESS" },
          { label: "label.status.failed", value: "COMPLETED_ERROR" },
          { label: "label.status.rejected", value: "REJECTED" },
          { label: "label.status.pending", value: "TO_BE_APPROVED" },
          {
            label: "label.status.overBudget",
            value: "TO_BE_APPROVED_FOR_OVERBUDGET",
          },
        ],
      },
      {
        name: "startDate",
        label: "label.massiveRewards.startDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "endDate",
        label: "label.massiveRewards.endDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
    ];

    let url = `/gov/api/rest/v1/massiveRewards/typeList?partnerId=${brand.id}&brandId=${partner.id}&singlePlayer=false`;
    const response = await axios.get(url);
    if (response && response.data) {
      let rewardType = {
        name: "rewardTypeId",
        label: "label.massiveRewards.rewardType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: [],
      };
      response.data.forEach((it) => {
        rewardType["options"].push({
          label: "label.rewardType." + it.rewardTypeId,
          value: it.rewardTypeId,
        });
      });
      filter.push(rewardType);
    }
    return filter;
  }

  async bulkOperationList(brand, partner, searchTerms) {
    let url = `/gov/api/rest/v1/massiveRewards/requestsList?partnerId=${brand.id}&brandId=${partner.id}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return {
        fullData: response.data,
        data: searchUtils.search(response.data, searchTerms),
      };
    }
    throw Error("Invalid data");
  }

  async bulkOperationTransaction(massiveId, query) {
    const response = await axios.get(
      `/gov/api/rest/v2/massiveRewards/${massiveId}?pageNumber=${query.page}&pageSize=${query.pageSize}`
    );

    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response for expiring bonus view");
  }

  async approveRejectReward(rewardId, isApproved) {
    let url = `/gov/api/rest/v2/approvalProcess/approve_reject/${rewardId}`;
    const body = {
      approvalIdentifier: rewardId,
      approve: isApproved,
      processRewardType: "BULK",
    };
    const response = await axios.put(url, body);

    if (response) {
      return response;
    }
    throw Error("Invalid response for expiring bonus view");
  }

  async desk(data) {
    const response = await axios.put(`/gov/api/rest/v2/desk/${data.id}`, data);
    if (response && response.status === 204) {
      return response;
    }
  }

  async deskByApprovalId(rewardId) {
    const response = await axios.get(
      `/gov/api/rest/v2/desk_by_approval_id/${rewardId}`
    );
    if (response && response.data) {
      return response.data;
    }
  }
}

const bulkOperationListService = new BulkOperationListService();
export default bulkOperationListService;
