import { Button, Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import SpinnerButton from "../spinner-button/spinner-button";

const buttonActions = ["button.cancel", "button.save"];
const Action = ({ isLoading, tablet = false, ...props }) => {
  const t = useTranslator();
  const [open, setOpen] = React.useState(null);

  let action = props.action;

  if (typeof action === "function") {
    action = action(props.data);
    if (!action) {
      return null;
    }
  }

  if (action.action) {
    action = action.action(props.data);
    if (!action) {
      return null;
    }
  }

  if (action.hidden) {
    return null;
  }

  if (
    isLoading &&
    (action.position === undefined || action.position === "row")
  ) {
    return (
      <Box m={1} display={"flex"} width={64}>
        <Skeleton
          variant={tablet ? "circle" : "rect"}
          height={24}
          width={tablet ? 24 : "100%"}
        />
      </Box>
    );
  }

  let { color = "primary", variant = "contained", order = 0 } = action;

  if (action.tooltip === "button.delete") {
    color = "failure";
    order = 1;
  }
  if (action.tooltip === "button.cancel") {
    variant = "outlined";
  }
  if (action.tooltip === "button.save") {
    order = 1;
  }

  const actionProps = {
    className: !tablet ? "tableActionButton" : "",
    size: props.size,
    color,
    style: {
      order,
      color:
        tablet && ["toolbar", "toolbarOnSelect"].includes(action.position)
          ? "white"
          : "auto",
    },
    onClick: (event) => {
      action.onClick(event, props.data);
      event.stopPropagation();
    },
  };
  if (action.menu) {
    actionProps.onClick = (event) => setOpen(event.currentTarget);
  }

  const tooltipTitle =
    props.isEditDisabled &&
    action.tooltip === "button.edit" &&
    props.disabled &&
    props.editTooltip
      ? props.editTooltip
      : action.tooltipTitle;

  const text = <Box whiteSpace={"noWrap"}>{t({ needle: action.tooltip })}</Box>;
  const getAction = () => {
    return (
      <>
        {tablet && !buttonActions.includes(action.tooltip) ? (
          <IconButton {...actionProps} disabled={props.isEditDisabled
          ? props.disabled
          : action.disabled}>
            {action.icon ? (
              <SvgIcon
                style={{ color: "inherit", fontSize: "1.4em" }}
                component={action.icon}
              />
            ) : null}
          </IconButton>
        ) : (
          <Tooltip
            title={tooltipTitle}
            disableHoverListener={!Boolean(tooltipTitle)}
            placement={"left"}
            PopperProps={{
              style: {
                maxWidth: 200,
              },
            }}
          >
            <Box>
              <Button
                {...actionProps}
                disabled={
                  props.isEditDisabled && action.tooltip === "button.edit"
                    ? props.disabled
                    : action.disabled
                }
                variant={variant}
                startIcon={
                  action.icon ? (
                    <SvgIcon
                      style={{ color: "inherit" }}
                      component={action.icon}
                    />
                  ) : null
                }
              >
                {text}
              </Button>
            </Box>
          </Tooltip>
        )}
        {action.menu && open ? (
          <action.menu
            anchorEl={open}
            handleClose={() => setOpen(null)}
            {...action.p}
          />
        ) : null}
      </>
    );
  };

  return action.isSpinning ? (
    <SpinnerButton
      loading={action.isSpinning}
      key={action.tooltip}
      onClick={action.handler}
      color={action.color || "primary"}
      variant={action.variant || "contained"}
      label={action.tooltip}
    >
      {text}
    </SpinnerButton>
  ) : (
    getAction()
  );
};

export default Action;
