import axios from "axios";

class BlocklistService {
  async addRemoveBlock(contractId, brand, partner, note, isBlacklisted) {
    const url = `/gov/api/rest/v1/sms/blacklist/${contractId}?brand=${brand}&partner=${partner}`;

    const response = await axios({
      method: isBlacklisted ? "delete" : "post",
      url,
      data: {
        reason: note,
      },
    });

    if (!response || response.status !== 204) {
      throw Error("Invalid response for add/remove block");
    }
  }
}

const blocklistService = new BlocklistService();
export default blocklistService;
