import Box from "@material-ui/core/Box";
import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../../Layouts/Currency/Currency";
import {
  getPatternValidation,
  getValidationByType,
} from "../../../../../Layouts/form/utils";
import AmountProgressBar from "../../../../../Layouts/progress-bar/amount-progress-bar";
import { getDataValue } from "../../../../../Layouts/Table/utils";
import Translate from "../../../../../Layouts/Translator/Translate";
import {
  CHANGE_PLAYER_LIMITS_STATUS,
  COLUMN_PREFIX,
  COOLOFF_MESSAGE_PREFIX,
  GET_PLAYER_LIMITS,
  GET_PLAYER_LIMIT_HISTORY,
  HISTORY_COLUMN_PREFIX,
  UPDATE_PLAYER_LIMITS,
} from "./actions";
import { convertFromMillsToHoursMinSecs } from "../../../../../utils";
import TimeProgressBar from "../../../../../Layouts/progress-bar/time-progress-bar";
import { SESSION_DIVISOR } from "../../../../constants";

const getStatusMapping = (status) => {
  return status
    ? { id: "2", label: "enabled" }
    : { id: "5", label: "disabled" };
};

const initialState = {
  playerLimitHistory: {
    data: [],
    loading: true,
    columns: () => {
      return [
        {
          field: "transactionDate",
          title: HISTORY_COLUMN_PREFIX + "transactionDate",
          render: (rowData) => {
            if (rowData["transactionDate"]) {
              return (
                <Moment format="L LT">{rowData["transactionDate"]}</Moment>
              );
            }
          },
        },
        {
          field: "previousValue",
          title: HISTORY_COLUMN_PREFIX + "previousValue",
          render: (rowData) => {
            const previousValue = rowData["previousValue"] || 0;

            return rowData["limitType"].split("_").shift() == "SESSION" ? (
              convertFromMillsToHoursMinSecs(previousValue)
            ) : (
              <Currency amount={previousValue} currency={rowData["limitType"].indexOf("LOYALTY")>=0?"POINTS":null} applyDivisorAmount={true} currencyPrecision={rowData["limitType"].indexOf("LOYALTY")>=0?"POINTS":null}/>
            );
          },
        },
        {
          field: "currentValue",
          title: HISTORY_COLUMN_PREFIX + "currentValue",
          render: (rowData) => {
            const currentValue = rowData["currentValue"] || 0;

            return rowData["limitType"].split("_").shift() == "SESSION" ? (
              convertFromMillsToHoursMinSecs(currentValue)
            ) : (
              <Currency amount={currentValue} currency={rowData["limitType"].indexOf("LOYALTY")>=0?"POINTS":null} applyDivisorAmount={true} currencyPrecision={rowData["limitType"].indexOf("LOYALTY")>=0}/>

            );
          },
        },
        {
          field: "changeSource",
          title: HISTORY_COLUMN_PREFIX + "changeSource",
        },
      ];
    },
  },
  playerLimits: {
    loading: true,
    data: [],
    columns: () => {
      return [
        {
          field: "type",
          title: COLUMN_PREFIX + "limitName",
          default: true,
          defaultSort: "asc",
          editable: false,
        },
        {
          field: "limit",
          title: COLUMN_PREFIX + "limitValue",
          nowrap: false,
          editComponentDef: {
            name: "limit",
            labelPrefix: COLUMN_PREFIX,
            type: "Integer",
          },
          render: (rowData, column) => {
            if (rowData["group"] == "SESSION") {
              const currentValue =
                rowData["used"] * SESSION_DIVISOR || 0;
              const limitValue =
                getDataValue(rowData, column) * SESSION_DIVISOR || 0;
              return (
                <TimeProgressBar value={currentValue} limit={limitValue} />
              );
            } else {
              const currentValue = rowData["used"] || 0;
              const limitValue = getDataValue(rowData, column) || 0;
              return (
                <AmountProgressBar
                  value={currentValue}
                  limit={limitValue}
                  applyDivisorAmount={false}
                  currency={rowData["group"] == "LOYALTY_POINT_EARN_LIMIT"?"POINTS":null}
                />
              );
            }
          },
        },
        {
          field: "threshold",
          title: COLUMN_PREFIX + "limitThreshold",
          nowrap: false,
          editable: (row, rowData) => {
            return !(
              rowData["type"] == "SESSION_LIMIT_SINGLE"
            );
          },
          editComponentDef: (row) => {
            return {
              name: "threshold",
              labelPrefix: COLUMN_PREFIX,
              type: "Text",
              validation: getPatternValidation(
                getValidationByType("Text"),
                "^[1-9]{1}[0-9]?$|^$",
                COLUMN_PREFIX,
                "limitThreshold",
                <Translate
                  needle={
                    "label.individualplayer.responsibleGame.playerLimits.message.limitThreshold.invalidLimitThreshold"
                  }
                ></Translate>
              ),
            };
          },
          render: (rowData, column) => {
            if (rowData["threshold"] == "") return <>-</>;
            return <>{rowData["threshold"]}%</>;
          },
        },
        {
          field: "enabled",
          title: COLUMN_PREFIX + "status",
          editable: false,
          default: true,
          render: (rowData) => {
            const badgeMapped = getStatusMapping(rowData["enabled"]);
            return (
              <>
                <BadgeStatus {...badgeMapped} justify="center" />
                {rowData["enabled"] &&
                rowData["disablingDate"] != null ? (
                  <Translate
                    needle={COOLOFF_MESSAGE_PREFIX + "disabling"}
                    variables={[
                      moment(rowData["disablingDate"]).format("L LT"),
                    ]}
                  />
                ) : null}
              </>
            );
          },
        },
        {
          field: "updated",
          title: COLUMN_PREFIX + "updateDate",
          default: true,
          editable: false,
          render: (rowData) => {
            if (rowData["updated"] != null) {
              return <Moment format="L LT">{rowData["updated"]}</Moment>;
            }
          },
        },
        {
          field: "nextValueStartDate",
          title: COLUMN_PREFIX + "cooloffReservation",
          editable: false,
          nowrap: false,
          render: (rowData) => {
            const reserveProfile = rowData["nextValue"];
            // const measure = rowData["measure"];
            if (reserveProfile != null && reserveProfile + "" !== "0") {
              return (
                <Box>
                  {rowData["group"] == "SESSION" ?
                    convertFromMillsToHoursMinSecs(getDataValue(rowData,{field: "nextValue"})) || 0:
                    <Currency
                    amount={reserveProfile}
                    // currency={measure}
                    applyDivisorAmount={true}
                    justify={"flex-end"}
                  />}


                  <Translate
                    needle={COOLOFF_MESSAGE_PREFIX + "limitValue"}
                    variables={[
                      moment(rowData["nextValueStartDate"]).format("L LT"),
                    ]}
                  />
                </Box>
              );
            } else {
              return (
                <Translate needle={COOLOFF_MESSAGE_PREFIX + "noReservation"} />
              );
            }
          },
        },
      ].filter((it) => !it.hidden);
    },
  },
};

const PlayerLimitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PLAYER_LIMITS.REQUEST:
    case CHANGE_PLAYER_LIMITS_STATUS.REQUEST:
    case GET_PLAYER_LIMITS.REQUEST: {
      return {
        ...state,
        playerLimits: {
          ...state.playerLimits,
          loading: true,
        },
      };
    }

    case GET_PLAYER_LIMITS.FAILURE: {
      return {
        ...state,
        playerLimits: {
          ...state.playerLimits,
          loading: false,
          data: [],
        },
      };
    }

    case GET_PLAYER_LIMITS.SUCCESS: {
      return {
        ...state,
        playerLimits: {
          ...state.playerLimits,
          data: action.data,
          loading: false,
        },
      };
    }

    case GET_PLAYER_LIMIT_HISTORY.REQUEST: {
      return {
        ...state,
        playerLimitHistory: {
          ...state.playerLimitHistory,
          loading: true,
          data: [],
        },
      };
    }

    case GET_PLAYER_LIMIT_HISTORY.SUCCESS: {
      return {
        ...state,
        playerLimitHistory: {
          ...state.playerLimitHistory,
          loading: false,
          data: action.data,
        },
      };
    }

    case GET_PLAYER_LIMIT_HISTORY.FAILURE: {
      return {
        ...state,
        playerLimitHistory: {
          ...state.playerLimitHistory,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default PlayerLimitsReducer;
