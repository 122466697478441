import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getIndividualPlayerLoginHistoryHandler } from "../../store/players/individual-player/login-history/actions";

export const useIndividualPlayerLoginHistory = (contractId, brand, partner) => {
  const { data, columns, fields, loading } = useSelector(
    (state) => state.LoginHistoryReducer.loginHistoryList
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getIndividualPlayerLoginHistoryHandler(contractId, brand, partner)
    );
  }, [dispatch, contractId, brand, partner]);

  return { data, columns, fields, loading };
};
