import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import {
  GAME_REPLAY_FAILURE,
  GAME_REPLAY_REQUEST,
  GAME_REPLAY_SUCCESS,
  GAME_TRANSACTION_DETAIL_FAILURE,
  GAME_TRANSACTION_DETAIL_REQUEST,
  GAME_TRANSACTION_DETAIL_SUCCESS,
  GAME_TRANSACTIONS_LIST_FAILURE,
  GAME_TRANSACTIONS_LIST_REQUEST,
  GAME_TRANSACTIONS_LIST_SUCCESS,
} from "../../../constants";
import gameTransactionsService from "./game-transactions-service";
import { labelPrefix } from "./game-transactions-reducer";
import { getToken } from "../../../external-url/external-url-saga";

const getGameTransactions = function* (action) {
  try {
    let searchTerms = {
      dateFrom: action.searchTerms.creationPeriod.from
        ? action.searchTerms.creationPeriod.from
        : "",
      dateTo: action.searchTerms.creationPeriod.to
        ? action.searchTerms.creationPeriod.to
        : "",
    };
    const data = yield call(
      gameTransactionsService.getGameTransactions,
      action.contractId,
      action.brand,
      action.partner,
      searchTerms,
      action.pageNumber,
      action.pageSize,
      action.orderBy
    );
    yield put({
      type: GAME_TRANSACTIONS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("gameTransactions", e);
    yield put({
      type: GAME_TRANSACTIONS_LIST_FAILURE,
    });
  }
};

const getGameTransactionDetails = function* (action) {
  try {
    const data = yield call(
      gameTransactionsService.getGameTransactionDetails,
      action.contractId,
      action.brand.id,
      action.partner.id,
      action.gameSessionId,
      action.platformId
    );
    yield put({
      type: GAME_TRANSACTION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getGameTransactionDetails", e);
    yield put({
      type: GAME_TRANSACTION_DETAIL_FAILURE,
    });
  }
};

const getGameReplay = function* (action) {
  try {
    const data = yield call(
      gameTransactionsService.getGameReplay,
      action.contractId,
      action.brand.id,
      action.partner.id,
      action.gameCode,
      action.gameSessionId,
      action.roundId,
      action.languageCode,
      action.platformId
    );

    const { type, content } = data;

    if (type === "url") {
      const tokenResponse = yield call(getToken, encodeURIComponent(content));
      window.open(
        content + "&gmsUserAuthToken=" + tokenResponse.data.token,
        "label.gameReplayUrl",
	    "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,,left=0"
      );
    } else if (type === "html") {
      const win = window.open(
        "",
        "label.gameReplayUrl",
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,,left=0"
      );
      win.document.body.innerHTML = content;
    } else {
      yield put({
        type: GAME_REPLAY_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    console.error("getGameReplay", e);
    yield put({
      type: GAME_REPLAY_FAILURE,
    });
    let message = {
      needle: "errors.generic_error",
      variables: [labelPrefix + "gameReplay"],
    };
    if (e.response && e.response.data && e.response.data.description) {
      message = e.response.data.description;
    }
  }
};

const GameTransactionsSaga = function* gameTransactionsSaga() {
  yield takeLatest(GAME_TRANSACTIONS_LIST_REQUEST, getGameTransactions);
  yield takeLatest(GAME_TRANSACTION_DETAIL_REQUEST, getGameTransactionDetails);
  yield takeLatest(GAME_REPLAY_REQUEST, getGameReplay);
};

export default GameTransactionsSaga;
