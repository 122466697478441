import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExportType } from "../../utilities/dataExporter";
import Translate from "../Translator/Translate";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const ExportMenu = ({
  anchorEl,
  handleClose,
  handleExport,
  data,
  searchText,
}) => {
  const classes = useStyle();
  const [addFilters, setAddFilters] = React.useState(false);

  const handleClick = (type) => {
    handleExport(type, data, addFilters, searchText);
  };

  return (
    <Menu
      className={classes.exportMenu}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      MenuListProps={{ disablePadding: true }}
    >
      <MenuItem button onClick={() => handleClick(ExportType.CSV)}>
        <Translate needle={"label.exportCsv"} />
      </MenuItem>
      <MenuItem button onClick={() => handleClick(ExportType.EXCEL)}>
        <Translate needle={"label.exportExcel"} />
      </MenuItem>
      <MenuItem button onClick={() => handleClick(ExportType.PDF)}>
        <Translate needle={"label.exportPDF"} />
      </MenuItem>
      <MenuItem dense button onClick={() => setAddFilters((it) => !it)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={addFilters} />
        </ListItemIcon>
        <ListItemText>
          <Translate needle={"label.exportFilterFields"} />
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

const useStyle = makeStyles((theme) => ({
  exportMenu: {
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
  },
}));

export default ExportMenu;
