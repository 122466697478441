import Box from "@material-ui/core/Box";
import FormTooltip from "./form-tooltip";
import React from "react";
import PropTypes from "prop-types";
import Translate from "../Translator/Translate";

const FormLabel = ({ optional = false, label, tooltip }) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      {label}
      {optional ? (
        <>
          &nbsp;
          <Translate needle={"label.form.optional"} />
        </>
      ) : null}
      {tooltip ? <FormTooltip {...tooltip} /> : null}
    </Box>
  );
};

FormLabel.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  tooltip: PropTypes.shape({
    title: FormTooltip.propTypes.title,
  }),
};

export default FormLabel;
