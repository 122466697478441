import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  AUTHENTICATION_REQUEST,
  LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST,
  LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS,
  LOGIN_ACTION_DESCRIPTION,
  LOGIN_FAILED_ACTION_DESCRIPTION,
  LOGIN_FAILED_OPERATION_TYPE_ID,
  LOGIN_OPERATION_TYPE_ID,
  SESSION_INFO_REQUEST,
} from "../constants";

const gkAuthentication = async ({
  username,
  password,
  externalLoginPlugin,
}) => {
  let authenticated = {};
  let result = false;
  let response;
  let errorCode = null;

  response = await axios.post(
    "/gov/api/rest/v1/users/jwt-login",
    {
      username,
      password,
      externalLoginPlugin,
    }
  );
  if (response && response.data) {
    authenticated = {
      result: true,
      errorCode,
    };

    sessionStorage.setItem("access-token", response.data.accessToken);
    sessionStorage.setItem("refresh-token", response.data.refreshToken);

    return authenticated
  }

};

const updateAudit = async (auditData) => {
  await axios.post("/gov/api/rest/v1/audit", auditData)
};

const authentication = function* (action) {
  let data = { result: false, errorCode: "1002" };
  let auditData = {};
    try {
      data = yield call(gkAuthentication, action.payload);
      auditData = {
        operationType: {id: LOGIN_OPERATION_TYPE_ID},
        moduleDescription: LOGIN_ACTION_DESCRIPTION,
        actionDescription: LOGIN_ACTION_DESCRIPTION,
      };
    } catch (e) {
      auditData = {
        operationType: {id: LOGIN_FAILED_OPERATION_TYPE_ID},
        user: {username: action.payload.username},
        moduleDescription: LOGIN_FAILED_ACTION_DESCRIPTION,
        actionDescription: LOGIN_FAILED_ACTION_DESCRIPTION,
        brandLogin: action.payload.brandLogin,
        partnerLogin: action.payload.partnerLogin,
      };
      let errorCode =
          e.response.data != null && e.response.data !== ""
              ? e.response.data.ERROR_CODE
              : "1002";
      let username = action.payload.username;
      data = {
        result: false,
        errorCode: errorCode.toString(),
        username,
      };
    }
    //yield call(updateAudit, auditData);
  yield put({
    type: SESSION_INFO_REQUEST,
    payload: data,
  });
};

const loadExternalLoginPlugins = function* () {
  let list = [];
  try {
    list = yield call(externalLoginPluginService);
  } catch (e) {
    console.error("searchDeskReward", e);
  }
  const externalLoginPlugins = list.reduce(
    (accumulator, plugin) => {
      accumulator.push({
        key: plugin["id"],
        label: plugin["brand"]["name"] + " - " + plugin["partner"]["name"],
      });
      return accumulator;
    },
    [{ key: "-1", label: "label.login.crossBrandPartner" }]
  );
  yield put({
    type: LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS,
    payload: externalLoginPlugins,
  });
  yield put({
    type: "LOAD_WHOLE_EXTERNAL_LOGIN_PLUGINS_SUCCESS",
    payload: list,
  });
};

const externalLoginPluginService = async () => {
  let url = "/gov/api/bam-rest/v1/external-login-plugin";
  const response = await axios.get(url);
  if (response && response.data) {
    return response.data["collection"];
  }
  return [];
};

const LoginSaga = function* loginSaga() {
  yield takeEvery(AUTHENTICATION_REQUEST, authentication);
  yield takeEvery(
    LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST,
    loadExternalLoginPlugins
  );
};

export default LoginSaga;
