import {
  CONSENTS_REQUEST,
  PLAYER_LEVEL_FIELDS_REQUEST,
  PLAYER_LEVELS_REQUEST,
  PLAYER_SAVE_REQUEST,
  UPGRADE_PLAYER_SAVE_REQUEST,
  PLAYERS_ID_LIST_REQUEST,
  PLAYERS_SEARCH_CRITERIA_REQUEST,
  PLAYERS_SEARCH_REQUEST,
  REACTIVATE_GAME_ACCOUNT_REQUEST,
  SUSPEND_GAME_ACCOUNT_REQUEST,
  START_ACCOUNT_CLOSURE_REQUEST,
  CANCEL_ACCOUNT_CLOSURE_REQUEST,
  CONFIRM_ACCOUNT_CLOSURE_REQUEST,
  CASH_OUT_REQUEST,
  ORDER_ACTION_REQUEST,
  ORDER_TYPE_REQUEST,
  SAVE_ORDER_REQUEST,
  PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS,
  DELETE_PLATFORM_NICKNAME_REQUEST,
  CONVERT_TEST_PLAYER_ACCOUNT_REQUEST,
  PEP_PROCESS_REQUEST,
  BLOCKADE,
} from "../constants";

export const loadPlayersCriteriaFields = (brand, partner) => {
  return {
    type: PLAYERS_SEARCH_CRITERIA_REQUEST,
    brand,
    partner,
  };
};

export const searchContractHeaderHandler = (contractToSearch) => {
  return {
    type: PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS,
    contractToSearch,
  };
};

export const searchPlayers = (searchParams, sensitiveVip) => {
  return {
    type: PLAYERS_SEARCH_REQUEST,
    searchParams,
    sensitiveVip,
  };
};

export const getPlayersIdList = (searchParams) => {
  return {
    type: PLAYERS_ID_LIST_REQUEST,
    searchParams,
  };
};

export const getPlayerLevels = (brand, partner) => {
  return {
    type: PLAYER_LEVELS_REQUEST,
    brand,
    partner,
  };
};

export const getFields = (brand, partner, level, contractId, upgrade) => {
  return {
    type: PLAYER_LEVEL_FIELDS_REQUEST,
    brand,
    partner,
    level,
    contractId,
    upgrade,
  };
};

export const getConsents = (brand, partner, language, registrationLevelId) => {
  return {
    type: CONSENTS_REQUEST,
    brand,
    partner,
    language,
    registrationLevelId,
  };
};

export const savePlayer = (
  brand,
  partner,
  body,
  contractId,
  playerId,
  isUpgrade,
  isUpgraded,
  cb
) => {
  return {
    type: !isUpgrade ? PLAYER_SAVE_REQUEST : UPGRADE_PLAYER_SAVE_REQUEST,
    brand,
    partner,
    contractId,
    playerId,
    body,
    isUpgrade,
    isUpgraded,
    cb,
  };
};

export const suspendActivateAccount = (
  contractId,
  playerId,
  body,
  action,
  cb,
  errCb
) => {
  return {
    type:
      action === "suspend"
        ? SUSPEND_GAME_ACCOUNT_REQUEST
        : REACTIVATE_GAME_ACCOUNT_REQUEST,
    contractId,
    playerId,
    body,
    cb,
    errCb,
  };
};

export const startAccountClosure = (
  contractId,
  playerId,
  brand,
  partner,
  removeData,
  reason,
  cb,
  errCb,
  username
) => {
  return {
    type: START_ACCOUNT_CLOSURE_REQUEST,
    contractId,
    playerId,
    brand,
    partner,
    removeData,
    reason,
    cb,
    errCb,
    username,
  };
};

export const cancelAccountClosure = (
  contractId,
  playerId,
  brand,
  partner,
  cb,
  errCb
) => {
  return {
    type: CANCEL_ACCOUNT_CLOSURE_REQUEST,
    contractId,
    playerId,
    brand,
    partner,
    cb,
    errCb,
  };
};

export const confirmAccountClosure = (
  contractId,
  playerId,
  brand,
  partner,
  cb,
  errCb,
  username
) => {
  return {
    type: CONFIRM_ACCOUNT_CLOSURE_REQUEST,
    contractId,
    playerId,
    brand,
    partner,
    cb,
    errCb,
    username,
  };
};

export const confirmBlockade = (
  contractId,
  playerId,
  brand,
  partner,
  cb,
  errCb,
  username
) => {
  return {
    type: BLOCKADE.REQUEST,
    contractId,
    playerId,
    brand,
    partner,
    cb,
    errCb,
    username,
  };
};

export const cashOut = (body, cb, errCb) => {
  return {
    type: CASH_OUT_REQUEST,
    body,
    cb,
    errCb,
  };
};

export const getOrderActions = () => {
  return {
    type: ORDER_ACTION_REQUEST,
  };
};

export const getOrderTypes = () => {
  return {
    type: ORDER_TYPE_REQUEST,
  };
};

export const saveOrder = (body, cb, errCb) => {
  return {
    type: SAVE_ORDER_REQUEST,
    body,
    cb,
    errCb,
  };
};

export const deletePlatformNickname = (
  brand,
  partner,
  contractId,
  nicknameGns,
  cb,
  errCb
) => {
  return {
    type: DELETE_PLATFORM_NICKNAME_REQUEST,
    brand,
    partner,
    contractId,
    nicknameGns,
    cb,
    errCb,
  };
};

export const convertTestPlayerAccount = (
  contractId,
  playerId,
  body,
  cb,
  errCb
) => {
  return {
    type: CONVERT_TEST_PLAYER_ACCOUNT_REQUEST,
    contractId,
    playerId,
    body,
    cb,
    errCb,
  };
};
