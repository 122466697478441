import { select, takeLatest } from "@redux-saga/core/effects";
import {
  COOLDOWN_PERIOD_FAILURE,
  COOLDOWN_PERIOD_REQUEST,
  COOLDOWN_PERIOD_SUCCESS,
  NETWORK_BANS_ADD_BAN_FAILURE,
  NETWORK_BANS_ADD_BAN_REQUEST,
  NETWORK_BANS_ADD_BAN_SUCCESS,
  NETWORK_BANS_ADD_CATEGORY_FAILURE,
  NETWORK_BANS_ADD_CATEGORY_REQUEST,
  NETWORK_BANS_ADD_CATEGORY_SUCCESS,
  NETWORK_BANS_ADD_GAMES_FAILURE,
  NETWORK_BANS_ADD_GAMES_REQUEST,
  NETWORK_BANS_ADD_GAMES_SUCCESS,
  NETWORK_BANS_ADMIN_FILTERS_FAILURE,
  NETWORK_BANS_ADMIN_FILTERS_REQUEST,
  NETWORK_BANS_ADMIN_FILTERS_SUCCESS,
  NETWORK_BANS_ADMIN_LIST_FAILURE,
  NETWORK_BANS_ADMIN_LIST_REQUEST,
  NETWORK_BANS_ADMIN_LIST_SUCCESS,
  COOLDOWN_SAVE_REQUEST,
  NETWORK_BANS_REMOVE_BAN_REQUEST,
  NETWORK_BANS_REMOVE_BAN_FAILURE,
  NETWORK_BANS_REMOVE_BAN_SUCCESS,
  NETWORK_BANS_LIST_RELOAD,
  NETWORK_BANS_ALL_GAMES,
} from "../../../constants";
import { call, put } from "redux-saga/effects";
import networkBansService from "./network-bans-service";
import gameCategoriesService from "../../../game-categories/game-categories-service";
import { getGameCodes } from "../../../game-codes/game-codes-saga";
import moment from "moment";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadListFilter = function* (action) {
  try {
    const data = yield call(networkBansService.getListFilter, action.cb);
    yield put({
      type: NETWORK_BANS_ADMIN_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin Network Bans filter", e);
    yield put({
      type: NETWORK_BANS_ADMIN_FILTERS_FAILURE,
    });
  }
};

const loadListData = function* (action) {
  try {
    const data = yield call(
      networkBansService.getListData,
      action.brand.id,
      action.partner.id,
      action.searchTerms
    );
    yield put({
      type: NETWORK_BANS_ADMIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("Admin network Bans list ", e);
    yield put({
      type: NETWORK_BANS_ADMIN_LIST_FAILURE,
    });
  }
};

const loadCooldown = function* (action) {
  try {
    const data = yield call(
      networkBansService.getCooldown,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: COOLDOWN_PERIOD_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("Admin network Bans list ", e);
    yield put({
      type: COOLDOWN_PERIOD_FAILURE,
    });
  }
};

const loadGameCaegories = function* (action) {
  try {
    const data = yield call(gameCategoriesService.getCategories, action.code);
    yield put({
      type: NETWORK_BANS_ADD_CATEGORY_SUCCESS,
      payload: { data: data, index: action.index },
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: NETWORK_BANS_ADD_CATEGORY_FAILURE,
    });
  }
};

const loadGameCodes = function* (action) {
  try {
    let isNetworkBans = true;
    const data = yield call(getGameCodes, action, isNetworkBans);
    yield put({
      type: NETWORK_BANS_ADD_GAMES_SUCCESS,
      payload: { data: data, index: action.index },
    });
  } catch (e) {
    console.error("gamecodes", e);
    yield put({
      type: NETWORK_BANS_ADD_GAMES_FAILURE,
    });
  }
};

const addBan = function* ({ brand, partner, body }) {
  const banTypologies = [];
  let allGames = false;
  let allCategory = false;
  let index = 0;
  body["ban"].forEach((it) => {
    it["games"].forEach((game) => {
      if (game !== NETWORK_BANS_ALL_GAMES) {
        let splitGame = game.split("_");
        let banTypology = {};
        banTypology.gameDomain = it.gameDomain;
        banTypology.gameCategory = splitGame[1];
        banTypology.gameCode = splitGame[0];
        banTypologies[index] = banTypology;
        let categoryCheck = it["gameCategories"].indexOf(Number(splitGame[1]));
        if (categoryCheck >= 0) {
          it["gameCategories"].splice(categoryCheck, 1);
        }
        index++;
      } else {
        allGames = true;
      }
    });
    it["gameCategories"].forEach((category) => {
      if (category !== NETWORK_BANS_ALL_GAMES) {
        let banTypology = {};
        banTypology.gameDomain = it.gameDomain;
        banTypology.gameCategory = category;
        banTypology.gameCode = null;
        banTypologies[index] = banTypology;
        index++;
      } else {
        allCategory = true;
      }
    });
    if (allGames && allCategory) {
      let banTypology = {};
      banTypology.gameDomain = it.gameDomain;
      banTypology.gameCategory = null;
      banTypology.gameCode = null;
      banTypologies[index] = banTypology;
      index++;
    }
  });

  let banExpirationDate = null;

  if (body["typeBan"] !== "PERMANENT") {
    if (body["banPeriod"] === "0") {
      const splitPeriod = body["duration"].split("_");
      banExpirationDate = moment()
        .add(+splitPeriod[0], splitPeriod[1])
        .valueOf();
    } else {
      banExpirationDate = body["endDate"];
    }
  }

  let objToSend = {
    banTypologyDtos: banTypologies,
    banExpirationDate,
    reason: body["reason"],
    comment: body["notes"],
    eventType: body["event"] === "LOGIN" ? 1 : 2,
    bannedByBO: true,
    banType: body["typeBan"] === "TEMPORARY" ? 0 : 1,
    contractId: body["contractId"],
    brandId: brand.id,
    partnerId: partner.id,
  };

  if (body["banMode"] === "1") {
    delete objToSend.contractId;
    objToSend.fileName = body["csvFile"];
  }
  try {
    yield call(networkBansService.addBan, objToSend, body["banMode"]);
    yield put({
      type: NETWORK_BANS_ADD_BAN_SUCCESS,
    });
    yield put({
      type: NETWORK_BANS_LIST_RELOAD,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.admin.networkBans.create.add_successfully",
        },
        options: {
          key: "addPlayerBan",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("gamecodes", e);
    yield put({
      type: NETWORK_BANS_ADD_BAN_FAILURE,
    });
  }
};

const saveCooldown = function* (action) {
  try {
    yield call(
      networkBansService.saveCooldown,
      action.brand.id,
      action.partner.id,
      action.data
    );
    yield put({
      type: COOLDOWN_PERIOD_REQUEST,
      brand: action.brand,
      partner: action.partner,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.admin.networkBans.saveCoolDown",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("cooldown save ", e);
    yield put({
      type: COOLDOWN_PERIOD_FAILURE,
    });
  }
};

const removeBan = function* (action) {
  try {
    const data = yield call(
      networkBansService.removeBan,
      action.brand.id,
      action.partner.id,
      action.banId,
      action.contractId
    );
    yield put({
      type: NETWORK_BANS_REMOVE_BAN_SUCCESS,
      payload: data,
    });
    yield put({
      type: NETWORK_BANS_LIST_RELOAD,
    });
    action.cb != null && typeof action.cb === "function" && action.cb(true);
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.admin.networkBans.banRemoved",
        },
        options: {
          key: "removeBan",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("remove Ban ", e);
    yield put({
      type: NETWORK_BANS_REMOVE_BAN_FAILURE,
    });
  }
};

const reloadNetworkBansList = function* () {
  const lastRequest = yield select(
    (state) => state.NetworkBansAdminReducer.networkBansList.lastRequest
  );
  if (lastRequest) {
    yield put(lastRequest);
  }
};

const NetworkBansAdminSaga = function* NetworkBansAdminSaga() {
  yield takeLatest(NETWORK_BANS_ADMIN_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(NETWORK_BANS_ADMIN_LIST_REQUEST, loadListData);
  yield takeLatest(COOLDOWN_PERIOD_REQUEST, loadCooldown);
  yield takeLatest(NETWORK_BANS_ADD_CATEGORY_REQUEST, loadGameCaegories);
  yield takeLatest(NETWORK_BANS_ADD_GAMES_REQUEST, loadGameCodes);
  yield takeLatest(NETWORK_BANS_ADD_BAN_REQUEST, addBan);
  yield takeLatest(COOLDOWN_SAVE_REQUEST, saveCooldown);
  yield takeLatest(NETWORK_BANS_REMOVE_BAN_REQUEST, removeBan);
  yield takeLatest(NETWORK_BANS_LIST_RELOAD, reloadNetworkBansList);
};

export default NetworkBansAdminSaga;
