import React, { useEffect, useState } from "react";
import {
  getComunicationHistory,
  getComunicationDetails,
} from "../../../../../store/players/individual-player/communication-history/actions";
import { useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import Table from "../../../../../Layouts/Table/Table";
import Modal from "../../../../../Layouts/Modal/Modal";
import Visibility from "@material-ui/icons/Visibility";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { isMobileOnly } from "react-device-detect";
import Fieldset from "../../../../../Layouts/form/fieldset";
import Box from "@material-ui/core/Box";

function CommunicationHistory({
  brand,
  partner,
  widgetColumns,
  widget = false,
  loading,
  columns,
  data,
  getComunications,
  details,
  getDetails,
}) {
  const t = useTranslator();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const [isOpen, setIsOpen] = useState(false);
  const [orderBy, setOrderBy] = useState({field:"lastAttempt", order:"desc"})

  useEffect(() => {
    if(widget){
      getComunications(contractId, 0, 10);

    }
  }, [getComunications, contractId, widget]);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const openDetail = (rowData) => {
    const { extNotificationKey, extContactKey, channelName } = rowData;
    getDetails(extNotificationKey, extContactKey, brand, partner, channelName);
    handleOpenModal();
  };

  const getTableContent = (tableData) => {
    return (
      <Table
        orderBy={orderBy}
        isLoading={loading}
        title={"label.individualCommunicationHistory.title"}
        placeholder={"label.individualCommunicationHistory.searchPlaceholders"}
        type={widget ? "simple" : "advanced"}
        pagination={widget ? tableData!=null && tableData.length > 5 : true}
        columns={widget ? widgetColumns : columns}
        data={widget ? tableData: tableData ? {data: tableData.collection, totalCount: tableData.totalCount, page: --tableData.pageNumber} : null}
        exportButton={false}
        onFetch={widget ? undefined : (q)=>{setOrderBy(q.orderBy);getComunications(contractId, q.page, q.pageSize, q.orderBy)}}
        resize={!widget}
        pageSize={widget ? 5 : 10}
        actions={[
          {
            icon: Visibility,
            color: "primary",
            variant: "contained",
            tooltip: "button.view",
            onClick: (event, rowData) => {
              openDetail(rowData);
            },
          },
        ]}
      />
    );
  };
  return (
    <>
      {widget ? (
        <>
          <Fieldset
            legend={"label.individualCommunicationHistory.emailTitle"}
            collapse={true}
          >
            <Box mt={1}>
              {getTableContent(
                (data != null && Array.isArray(data.collection))
                  ? data.collection.filter((it) => it.channelName === "e-mail")
                  : null
              )}
            </Box>
          </Fieldset>
          <Box mt={1}>
            <Fieldset
              legend={"label.individualCommunicationHistory.inboxTitle"}
              collapse={true}
            >
              <Box mt={1}>
                {getTableContent(
                  (data != null && Array.isArray(data.collection))
                  ?data.collection.filter((it) => it.channelName === "inbox")
                    : null
                )}
              </Box>
            </Fieldset>
          </Box>
        </>
      ) : (
        getTableContent(data)
      )}
      <Modal
        maxWidth={"md"}
        scroll={"body"}
        fullScreen={isMobileOnly}
        handleClose={isMobileOnly ? handleOpenModal : null}
        open={isOpen}
        title={t({ needle: "label.modal.message" })}
        description={
          details ? (details.value ? details.value : details.description) : null
        }
        actions={[
          {
            color: "primary",
            label: "button.close",
            variant: "contained",
            handler: handleOpenModal,
          },
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    brandSelected,
    partnerSelected,
  } = state.AuthenticationReducer.session;
  const { loading, details } = state.CommunicationHistoryReducer;
  const {
    columns,
    data,
    widgetColumns,
  } = state.CommunicationHistoryReducer.communications;
  return {
    brand: brandSelected.id,
    partner: partnerSelected.id,
    loading,
    columns,
    data,
    details,
    widgetColumns,
  };
};

const mapDispatchToProps = {
  getComunications: getComunicationHistory,
  getDetails: getComunicationDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationHistory);
