import { select, takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  PROMO_CODE_AMOUNT_TYPES_FAILURE,
  PROMO_CODE_AMOUNT_TYPES_REQUEST,
  PROMO_CODE_AMOUNT_TYPES_SUCCESS,
  PROMO_CODE_CAMPAIGNS_FAILURE,
  PROMO_CODE_CAMPAIGNS_REQUEST,
  PROMO_CODE_CAMPAIGNS_SUCCESS,
  PROMO_CODE_FAILURE,
  PROMO_CODE_FILTERS_FAILURE,
  PROMO_CODE_FILTERS_REQUEST,
  PROMO_CODE_FILTERS_SUCCESS,
  PROMO_CODE_RELOAD,
  PROMO_CODE_REQUEST,
  PROMO_CODE_SUCCESS,
  SAVE_PROMO_CODE_FAILURE,
  SAVE_PROMO_CODE_REQUEST,
  SAVE_PROMO_CODE_SUCCESS,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import promoCodeService from "./promo-code-service";

const getPromoCodeFilters = function* (action) {
  try {
    const data = yield call(
      promoCodeService.getPromoCodeFilters,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: PROMO_CODE_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("promo code filter", e);
    yield put({
      type: PROMO_CODE_FILTERS_FAILURE,
    });
  }
};

const getPromoCodeList = function* (action) {
  try {
    const data = yield call(
      promoCodeService.getPromoCodeList,
      action.searchTerms
    );
    yield put({
      type: PROMO_CODE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("promo code list ", e);
    yield put({
      type: PROMO_CODE_FAILURE,
    });
  }
};

const getCampaigns = function* (action) {
  try {
    const data = yield call(
      promoCodeService.getCampaigns,
      action.brand,
      action.partner,
      action.promoType,
    );
    yield put({
      type: PROMO_CODE_CAMPAIGNS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("campaigns", e);
    yield put({
      type: PROMO_CODE_CAMPAIGNS_FAILURE,
    });
  }
};

const getAmountTypes = function* (action) {
  try {
    const data = yield call(promoCodeService.getAmountTypes);
    yield put({
      type: PROMO_CODE_AMOUNT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("amountTypes", e);
    yield put({
      type: PROMO_CODE_AMOUNT_TYPES_FAILURE,
    });
  }
};

const reloadPromoList = function* (action) {
  const lastRequest = yield select(
    (state) => state.PromoCodeReducer.promoCodeList.lastRequest
  );
  yield put(lastRequest);
};

const savePromoCode = function* (action) {
  try {
    const data = yield call(promoCodeService.savePromoCode, action.body);
    yield put({
      type: SAVE_PROMO_CODE_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.rewards.campaigns.promoCode.successAddPromo",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    action?.cb && typeof action?.cb === "function" && action.cb();

    yield put({
      type: PROMO_CODE_RELOAD,
    });
  } catch (e) {
    console.error("amountTypes", e);
    yield put({
      type: SAVE_PROMO_CODE_FAILURE,
    });
  }
};

const PromoCodeSaga = function* () {
  yield takeLatest(PROMO_CODE_FILTERS_REQUEST, getPromoCodeFilters);
  yield takeLatest(PROMO_CODE_REQUEST, getPromoCodeList);
  yield takeLatest(PROMO_CODE_CAMPAIGNS_REQUEST, getCampaigns);
  yield takeLatest(PROMO_CODE_AMOUNT_TYPES_REQUEST, getAmountTypes);
  yield takeLatest(SAVE_PROMO_CODE_REQUEST, savePromoCode);
  yield takeLatest(PROMO_CODE_RELOAD, reloadPromoList);
};

export default PromoCodeSaga;
