import React from "react";
import InputField from "./input-field";
import DatePickerField, {
  DateTimePickerField,
  TimePickerField,
} from "./date-picker-field";
import AutocompleteField from "./autocomplete-field";
import CheckboxField from "./checkbox-field";
import PasswordField from "./password-field";
import SliderField from "./slider-field";
import SelectField from "./select-field";
import NumericField from "./numeric-field";
import AmountField from "./amount-field";
import TagField from "./tag-field";
import FormField from "./form-field";
import ConditionalField from "./conditional-field";
import RadioField from "./radio-field";
import RangeField from "./range-field";
import FileField from "./file-field";
import ArrayField from "./array-field";
import AnniversaryField from "./anniversary-field";
import DecimalField from "./decimal-field";
import GroupField from "./group-field";
import SwitchField from "./switch-field";
import CroopieField from "./croppie-img-field";

export const fieldByType = {
  Switch: SwitchField,
  Text: InputField,
  DatePicker: DatePickerField,
  DateTimePicker: DateTimePickerField,
  TimePicker: TimePickerField,
  Select: ({ multiple, options = [], ...props }) =>
    multiple || options.length > 5 ? (
      <AutocompleteField multiple={multiple} options={options} {...props} />
    ) : (
      <SelectField options={options} {...props} />
    ),
  AutoComplete: AutocompleteField,
  Password: PasswordField,
  Checkbox: CheckboxField,
  MultipleRange: RangeField,
  Slider: SliderField,
  Integer: NumericField,
  Amount: AmountField,
  Tags: TagField,
  Conditional: ConditionalField,
  Radio: RadioField,
  File: FileField,
  Array: ArrayField,
  Anniversary: AnniversaryField,
  Decimal: DecimalField,
  GroupField: GroupField,
  Croopie: CroopieField,
};

export const typesComparation = {
  Switch: 100,
  Text: 1,
  DatePicker: 2,
  Select: 3,
  AutoComplete: 3,
};

export { FormField };
