import {
  COOLDOWN_PERIOD_FAILURE,
  COOLDOWN_PERIOD_SUCCESS,
  NETWORK_BANS_ADD_CATEGORY_SUCCESS,
  NETWORK_BANS_ADD_GAMES_SUCCESS,
  NETWORK_BANS_ADMIN_FILTERS_FAILURE,
  NETWORK_BANS_ADMIN_FILTERS_REQUEST,
  NETWORK_BANS_ADMIN_FILTERS_SUCCESS,
  NETWORK_BANS_ADMIN_LIST_FAILURE,
  NETWORK_BANS_ADMIN_LIST_REQUEST,
  NETWORK_BANS_ADMIN_LIST_SUCCESS,
  STYLE_LABEL,
} from "../../../constants";
import Moment from "react-moment";
import * as moment from "moment";
import React from "react";
import FormTooltip from "../../../../Layouts/form/form-tooltip";
import { Box } from "@material-ui/core";
import Translate from "../../../../Layouts/Translator/Translate";

export const labelPrefix = "label.admin.networkBans.";

const initialState = {
  networkBansList: {
    filters: [],
    filterKey: "",
    filtersLoading: true,
    tableLoading: true,
    data: [],
    cooldown: [],
    columns: (cooldown, isPlayer, t) => {
      const adminsCols = [
        {
          field: "contractId",
          title: labelPrefix + "contractId",
          default: true,
        },
        {
          field: "playerNickname",
          title: labelPrefix + "nickname",
        },
      ];
      let commonCols = [
        {
          field: "banTypologyDtos.0.gameDomain",
          title: labelPrefix + "gameDomain",
        },
        {
          field: "banTypologyDtos.0.gameCategory",
          title: labelPrefix + "gameCategories",
        },
        {
          field: "banTypologyDtos.0.gameCode",
          title: labelPrefix + "gameCode",
        },
        {
          field: "requestedScope",
          title: labelPrefix + "event",
        },
        {
          field: "banType",
          title: labelPrefix + "banType",
        },
        {
          field: "banExpirationDate",
          title: labelPrefix + "bannedUntil",
          nowrap: false,
          render: (rowData) => {
            let minDiffDate = moment(rowData["banExpirationDate"]).diff(
              rowData["unbanRequestedDate"],
              "hours"
            );
            if (rowData["banExpirationDate"]) {
              return (
                <Box display={"flex"}>
                  <Box mt={0.5}>
                    <Moment format="L LT">
                      {rowData["banExpirationDate"]}
                    </Moment>
                  </Box>
                  {cooldown != null && rowData["inCooldown"] && (
                    <Box>
                      <FormTooltip
                        title={
                          <Translate
                            needle={labelPrefix + "cooldownTooltip"}
                            variables={[
                              minDiffDate,
                              moment(rowData["unbanRequestedDate"]).format(
                                "L LT"
                              ),
                            ]}
                          />
                        }
                        placement="right"
                      />
                    </Box>
                  )}
                </Box>
              );
            }
          },
          getExportValueFn: (rowData) => {
            if (rowData["banExpirationDate"]) {
              return moment(rowData["banExpirationDate"]).format("L LT");
            }
          },
        },
        {
          field: "bannedByBO",
          title: labelPrefix + "banDoneBy",
          render: (rowData) => {
            if (rowData["bannedByBO"]) {
              return <Translate needle={labelPrefix + "boUser"} />;
            } else {
              return <Translate needle={labelPrefix + "Player"} />;
            }
          },
          getExportValueFn: (rowData) => {
            if (rowData["bannedByBO"]) {
              return t({ needle: labelPrefix + "boUser" });
            } else {
              return t({ needle: labelPrefix + "Player" });
            }
          },
        },
        {
          field: "reason",
          title: labelPrefix + "reason",
        },
        {
          field: "comment",
          title: labelPrefix + "note",
        },
      ];

      if (isPlayer) {
        return commonCols;
      } else {
        return [...adminsCols, ...commonCols];
      }
    },
  },
  networkBansCreate: {
    gameCategories: {},
    gameCodes: {},
  },
};

const NetworkBansAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_BANS_ADMIN_FILTERS_REQUEST:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
        },
      };
    case NETWORK_BANS_ADMIN_FILTERS_SUCCESS:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          filters: action.payload.filter,
          filterKey: action.payload.filterKey,
          filtersLoading: false,
        },
      };

    case NETWORK_BANS_ADMIN_FILTERS_FAILURE:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          filtersLoading: false,
        },
      };

    case NETWORK_BANS_ADMIN_LIST_REQUEST:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          lastRequest: action,
          tableLoading: true,
        },
      };

    case NETWORK_BANS_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          data: action.payload,
          tableLoading: false,
        },
      };

    case NETWORK_BANS_ADMIN_LIST_FAILURE:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          data: null,
          tableLoading: false,
        },
      };

    case COOLDOWN_PERIOD_SUCCESS:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          cooldown: action.payload,
        },
      };

    case COOLDOWN_PERIOD_FAILURE:
      return {
        ...state,
        networkBansList: {
          ...state.networkBansList,
          cooldown: null,
        },
      };

    case NETWORK_BANS_ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        networkBansCreate: {
          ...state.networkBansCreate,
          gameCategories: {
            ...state.networkBansCreate.gameCategories,
            [action.payload.index]: action.payload.data,
          },
        },
      };

    case NETWORK_BANS_ADD_GAMES_SUCCESS:
      return {
        ...state,
        networkBansCreate: {
          ...state.networkBansCreate,
          gameCodes: {
            ...state.networkBansCreate.gameCodes,
            [action.payload.index]: action.payload.data,
          },
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default NetworkBansAdminReducer;
