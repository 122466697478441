import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";

class GameTransactionsService {
  async getGameTransactions(
    contractId,
    brandId,
    partnerId,
    searchTerms,
    page,
    pageSize,
    orderBy
  ) {
    const order =
      orderBy != null
        ? searchUtils.getOrder(orderBy.order) + orderBy.field
        : null;
    const url = `/gov/api/rest/v2/players/${contractId}/game-sessions`;

    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms, false, false)
      : "";
    const response = await axios.get(url + queryParam, {
      params: {
        page,
        pageSize,
        brandId,
        partnerId,
        order,
      },
    });

    if (response && response.data && response.data.gameSessions) {
      return {
        data: response.data.gameSessions,
        page: page,
        totalCount: response.data.totalItems,
      };
    }
    throw Error("Invalid response for game transactions");
  }

  async getGameTransactionDetails(
    playerId,
    brandId,
    partnerId,
    gameSessionId,
    platformId
  ) {
    const url = `/gov/api/rest/v2/players/${playerId}/game-sessions/${gameSessionId}`;

    const response = await axios.get(url, {
      params: {
        brandId,
        partnerId,
        platformId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for game transaction details");
  }

  async getGameReplay(
    playerId,
    brand,
    partner,
    gameCode,
    gameSessionId,
    roundId,
    languageCode,
    platformId
  ) {
    const url = `/gov/api/rest/v1/game-replay-url/${gameCode}`;

    const response = await axios.get(url, {
      params: {
        brand,
        partner,
        "contract-id": playerId,
        "game-session-id": gameSessionId,
        "round-id": roundId,
        "language-code": languageCode,
        "platform-id": platformId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for game replay");
  }
}

const gameTransactionsService = new GameTransactionsService();
export default gameTransactionsService;
