import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  DEFAULT_LOCALE,
  IS_LOCAL,
  LOGOUT_ACTION,
  LOGOUT_ACTION_DESCRIPTION,
  LOGOUT_OPERATION_TYPE_ID,
  LOGOUT_REQUEST,
} from "../constants";
import { localeHandler } from "../locale/actions";
import { resetCounter } from "../../AuthenticatedApp";

const removeOperatorActivityListener = (it) => {
  it.removeEventListener("mousemove", resetCounter, false);
  it.removeEventListener("mousedown", resetCounter, false);
  it.removeEventListener("mouseover", resetCounter, false);
  it.removeEventListener("mouseout", resetCounter, false);
  it.removeEventListener("keypress", resetCounter, false);
  it.removeEventListener("DOMMouseScroll", resetCounter, false);
  it.removeEventListener("mousewheel", resetCounter, false);
  it.removeEventListener("touchmove", resetCounter, false);
  it.removeEventListener("MSPointerMove", resetCounter, false);
};

const logoutFunction = async ({ ipAddress, window }) => {
  await axios.post("/gov/api/rest/v1/audit", {
    operationType: { id: LOGOUT_OPERATION_TYPE_ID },
    moduleDescription: LOGOUT_ACTION_DESCRIPTION,
    actionDescription: LOGOUT_ACTION_DESCRIPTION,
    clientIp: ipAddress,
  });

  sessionStorage.clear();
};

const logout = function* (action) {
  let data = { result: false };
  if (IS_LOCAL) {
    sessionStorage.clear();
  } else {
    yield call(logoutFunction, action.payload);
  }
  removeOperatorActivityListener(window);
  yield put({
    type: LOGOUT_ACTION,
    payload: data,
  });
  yield put(localeHandler(DEFAULT_LOCALE));
};

const LogoutSaga = function* logoutSaga() {
  yield takeEvery(LOGOUT_REQUEST, logout);
};

export default LogoutSaga;
