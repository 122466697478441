import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_DATE, CRITERIA_TYPE_SELECT } from "../../../constants";
import { labelPrefix } from "./lottery-blocklist-reducer";

class LotteryBlocklistService {
  getListFilter = async () => {
    const filters = [
      {
        name: "ssn",
        label: labelPrefix + "ssn",
      },
      {
        name: "firstName",
        label: labelPrefix + "firstName",
      },
      {
        name: "lastName",
        label: labelPrefix + "lastName",
      },
      {
        name: "dateOfBirth",
        label: labelPrefix + "dateOfBirth",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "fileName",
        label: labelPrefix + "fileName",
      },
      {
        name: "type",
        label: labelPrefix + "type",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "manually", value: "MANUAL" },
          { label: labelPrefix + "byFile", value: "BY_FILE" },
        ],
      },
      {
        name: "addedFrom",
        label: labelPrefix + "addedFrom",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "addedTo",
        label: labelPrefix + "addedTo",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "editedFrom",
        label: labelPrefix + "editedFrom",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },

      {
        name: "editedTo",
        label: labelPrefix + "editedTo",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
    ];
    return filters;
  };

  loadLotteryBlocklist = async (query, searchTerms, brandId, partnerId) => {
    let url = `/gov/api/rest/v1/sms/blocklist`;
    const terms = searchTerms ? searchUtils.sanitizeTerms(searchTerms) : "";

    const response = await axios.get(url, {
      params: {
        brand: brandId,
        partner: partnerId,
        page: query.page + 1,
        pageSize: query.pageSize,
        sort: `${query.orderBy.field} ${query.orderBy.order}`,
        ...terms,
      },
    });
    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response lottery blocklist");
  };

  addEditToBlocklist = async (brandId, partnerId, values, addType) => {
    const url = `/gov/api/rest/v1/orc/blocklist?brand=${brandId}&partner=${partnerId}&reason=${values.reason}`;
    let response = [];

    const isByFile = addType === "BY_FILE";

    let dataForm = new FormData();
    if (isByFile) {
      dataForm.append("file", values.csvFile);
    }

    response = await axios({
      method: isByFile ? "post" : "put",
      url,
      data: isByFile ? dataForm : values,
      headers: {
        "Content-Type": isByFile ? "multipart/form-data" : "application/json",
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response while adding lottery blocklist");
  };

  removeFromBlocklist = async (brandId, partnerId, userBlacklistId) => {
    const url = `/gov/api/rest/v1/sms/blocklist/${userBlacklistId}?brand=${brandId}&partner=${partnerId}`;

    const response = await axios.delete(url);

    if (response && response.status !== 204) {
      throw Error("Invalid response while removing lottery blocklist");
    }
  };
}

const lotteryBlocklistService = new LotteryBlocklistService();
export default lotteryBlocklistService;
