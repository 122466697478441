import {
  LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS,
  LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE,
  LOYALTY_TRANSACTION_LIST_SUCCESS,
  LOYALTY_TRANSACTION_LIST_FAILURE,
  LOYALTY_TRANSACTION_LIST_REQUEST,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import * as moment from "moment";
import { FormattedNumber } from "react-intl";
import { Box } from "@material-ui/core";

const initialState = {
  transactionsList: {
    idKey: "transactionId",
    searchCriteria: [],
    columns: [
      {
        field: "transactionId",
        title: "label.individualplayer.loyaltyTransactions.transactionId",
        default: true,
        sorting: false,
      },
      {
        field: "transactionDate",
        title: "label.individualplayer.loyaltyTransactions.date",
        render: (rowData) => {
          if (rowData["transactionDate"]) {
            return <Moment format="L LT">{rowData["transactionDate"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["transactionDate"]) {
            return moment(rowData["transactionDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "gameDomain",
        title: "label.individualplayer.loyaltyTransactions.gameDomain",
        default: true,
      },        
      {
        field: "transactionType",
        title: "label.individualplayer.loyaltyTransactions.operationType",
        default: true,
      },
      {
        field: "levelPoints",
        title: "label.individualplayer.loyaltyTransactions.levelPoints",
        render: (rowData) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <FormattedNumber value={rowData["levelPoints"]} />
            </Box>
          );
        },
      },
      {
        field: "loyaltyPoints",
        title: "label.individualplayer.loyaltyTransactions.loyaltyPoints",
        render: (rowData) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <FormattedNumber value={rowData["loyaltyPoints"]} />
            </Box>
          );
        },
      },
      {
        field: "balance",
        title: "label.individualplayer.loyaltyTransactions.balance",
        render: (rowData) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <FormattedNumber value={rowData["balance"]} />
            </Box>
          );
        },
      },
      {
        field: "playerLevelName",
        title: "label.individualplayer.loyaltyTransactions.playerLevelName",
      },
      {
        field: "loyaltyPointConversionFactor",
        title: "label.individualplayer.loyaltyTransactions.loyaltyPointConversionFactor",
      },
      {
        field: "campaignId",
        title: "label.individualplayer.loyaltyTransactions.campaignId",
        render: (rowData) => {
          return rowData["campaignId"] == 0 ? "-" : rowData["campaignId"]
        },
      },
    ],
    data: [],
    loading: true,
  },
  transactionView: {
    fields: [
      {
        field: "transactionId",
        title: "label.individualplayer.loyaltyTransactions.transactionId",
      },
      {
        field: "transactionDate",
        title: "label.individualplayer.loyaltyTransactions.date",
        render: (rowData) => {
          if (rowData["timestamp"]) {
            return <Moment format="L LT">{rowData["timestamp"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "gameDomain",
        title: "label.individualplayer.loyaltyTransactions.gameDomain",
      },        
      {
        field: "transactionType",
        title: "label.individualplayer.loyaltyTransactions.operationType",
      },
      {
        field: "levelPoints",
        title: "label.individualplayer.loyaltyTransactions.levelPoints",
        render: (rowData) => {
          return (
            <Box display="flex">
              <FormattedNumber value={rowData["levelPoints"]} />
            </Box>
          );
        },
      },
      {
        field: "loyaltyPoints",
        title: "label.individualplayer.loyaltyTransactions.loyaltyPoints",
        render: (rowData) => {
          return (
            <Box display="flex">
              <FormattedNumber value={rowData["loyaltyPoints"]} />
            </Box>
          );
        },
      },
      {
        field: "balance",
        title: "label.individualplayer.loyaltyTransactions.balance",
        render: (rowData) => {
          return (
            <Box display="flex">
              <FormattedNumber value={rowData["balance"]} />
            </Box>
          );
        },
      },
      {
        field: "playerLevelName",
        title: "label.individualplayer.loyaltyTransactions.playerLevelName",
      },
      {
        field: "loyaltyPointConversionFactor",
        title: "label.individualplayer.loyaltyTransactions.loyaltyPointConversionFactor",
      },
      {
        field: "campaignId",
        title: "label.individualplayer.loyaltyTransactions.campaignId",
        render: (rowData) => {
          return rowData["campaignId"] == 0 ? "-" : rowData["campaignId"]
        },
      },
      {
        field: "bonusPointsDenied",
        title: "label.individualplayer.loyaltyTransactions.bonusPointsDenied",
        render: (rowData) => {
          return (
            <Box display="flex">
              <FormattedNumber value={rowData["bonusPointsDenied"]} />
            </Box>
          );
        },
      },
    ],
  },
};

const LoyaltyTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          searchCriteria: action.payload,
        },
      };
    }
    case LOYALTY_TRANSACTION_LIST_REQUEST: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          loading: true,
        },
      };
    }
    case LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE: {
      return {
        ...state,
        transactionsList: { ...state.transactionsList, searchCriteria: null },
      };
    }

    case LOYALTY_TRANSACTION_LIST_SUCCESS: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          data: action.payload.collection,
          totalCount: action.payload.totalCount,
          loading: false,
        },
      };
    }

    case LOYALTY_TRANSACTION_LIST_FAILURE: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          data: null,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default LoyaltyTransactionsReducer;
