import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  ADD_DOCUMENT_FAILURE,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCESS,
  DOCUMENT_TYPES_FAILURE,
  DOCUMENT_TYPES_REQUEST,
  DOCUMENT_TYPES_SUCCESS,
  PLAYER_DOCUMENTS_FAILURE,
  PLAYER_DOCUMENTS_HISTORY_FAILURE,
  PLAYER_DOCUMENTS_HISTORY_REQUEST,
  PLAYER_DOCUMENTS_HISTORY_SUCCESS,
  PLAYER_DOCUMENTS_REQUEST,
  PLAYER_DOCUMENTS_SUCCESS,
} from "../../../constants";
import playerDocumentsService from "./player-documents-services";
import { enqueueSnackbar } from "../../../snackbars/actions";


const getPlayerDocuments = function* (action) {
  try {
    const data = yield call(
      playerDocumentsService.getPlayerDocuments,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.registrationLevel
    );
    yield put({
      type: PLAYER_DOCUMENTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_DOCUMENTS_FAILURE,
    });
  }
};

const getPlayerDocumentsHistory = function* (action) {
  try {
    const data = yield call(
      playerDocumentsService.getPlayerDocumentsHistory,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.documentType
    );
    yield put({
      type: PLAYER_DOCUMENTS_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_DOCUMENTS_HISTORY_FAILURE,
    });
  }
};

const saveOrUpdatePlyDoc = function* (action) {
  try {
    yield call(
      playerDocumentsService.saveOrUpdatePlyDoc,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.registrationLevel,
      action.document
    );
    yield put({
      type: ADD_DOCUMENT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.playerDocuments.editSuccess",
        },
        options: {
          key: "saveOrUpdateDocumentSuccess",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    yield put({
      type: ADD_DOCUMENT_FAILURE,
    });
  }
};

const getDocumentTypes = function* (action) {
  try {
    const data = yield call(
      playerDocumentsService.getDocumentTypes,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.registrationLevel
    );
    yield put({
      type: DOCUMENT_TYPES_SUCCESS,
      payload: data,
    });

  } catch (e) {
    console.error("PlayerDocuments", e);
    yield put({
      type: DOCUMENT_TYPES_FAILURE,
    });
  }
};

const PlayerDocumentsSaga = function* playerDocumentsSaga() {
  yield takeLatest(PLAYER_DOCUMENTS_REQUEST, getPlayerDocuments);
  yield takeLatest(PLAYER_DOCUMENTS_HISTORY_REQUEST, getPlayerDocumentsHistory);
  yield takeLatest(ADD_DOCUMENT_REQUEST, saveOrUpdatePlyDoc);
  yield takeLatest(DOCUMENT_TYPES_REQUEST, getDocumentTypes);
};

export default PlayerDocumentsSaga;
