import React from "react";
import { connect } from "react-redux";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import IconButton from "@material-ui/core/IconButton";
// Import Custom Icon(s)
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Import Component(s)
import ProfileDropdown from "./ProfileDropdown";
import SettingsDropdown from "./SettingsDropdown";
import BookmarksDropdown from "./BookmarksDropdown";
// Import Icon(s)
import { ReactComponent as SettingsIcon } from "../../Images/Menu-Settings.svg";
import { ReactComponent as FavouriteIcon } from "../../Images/icon_favorites.svg";
import { ReactComponent as IconAvatar } from "../../Images/Icon-Avatar.svg";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory, useLocation } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Hidden, Input, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import menuItemsService from "../../utilities/menuItems";
import Typography from "@material-ui/core/Typography";
import {
  ProviderSelectionModal,
  ProviderSelectionPopover,
} from "./ProviderSelection";
import clsx from "clsx";
import Cancel from "@material-ui/icons/Cancel";
import { isMobileOnly } from "react-device-detect";
import Edit from "@material-ui/icons/Edit";
import { searchContractHeaderHandler } from "../../store/players/actions";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  svgIcon: {
    width: "35px",
    height: "35px",
  },
  root: {
    "& .MuiButton-root": {
      color: "inherit",
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.icon,
    },
    borderBottom: "1px solid " + theme.palette.divider,
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  logo: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  providerIcon: {
    marginLeft: theme.spacing(3),
  },
  startIcon: {
    marginLeft: "0",
  },
  selectedBookmark: {},
  bookmarkIcon: {
    "& svg path:nth-child(1)": {
      fill: "transparent",
    },
    "&$selectedBookmark svg path:nth-child(1)": {
      fill: theme.palette.text.highlight,
    },
  },
  buttonContainer: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "stretch",
    color: theme.palette.text.primary2,
    "& > .MuiButton-root": {
      "&.active": {
        color: theme.palette.text.highlight,
        "& svg": {
          color: theme.palette.text.highlight,
        },
      },
    },
    "&.mobile > .MuiButton-root": {
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  fontBold: {
    fontWeight: "bold",
  },
  cancelButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  openSearch: {
    width: "calc(100% - 8px)",
    marginLeft: theme.spacing(2),
    "& .MuiInput-input": {
      padding: theme.spacing(1),
    },
  },
  InputAdornment: {
    paddingLeft: 3,
    "& .MuiInput-input": {
      padding: "8px 3px",
    },
  },
  searchIcon: {
    width: 45,
    height: 45,
  },
  boxAdornment: {
    '& .MuiInput-input[value=""] + .MuiInputAdornment-positionEnd': {
      visibility: "hidden",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
      "& .MuiIconButton-root": {
        padding: 3,
      },
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: 0,
    },
  },
  providerBar: {
    cursor: "pointer",
    borderBottom: "1px solid " + theme.palette.divider,
  },
  logoHeight: {
    height: 50,
  },
}));

function Header(props) {
  const classes = useStyles();
  const t = useTranslator();
  let location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const [openProfile, setOpenProfile] = React.useState(null);
  const [openSettings, setOpenSettings] = React.useState(null);
  const [openBookmarks, setOpenBookmarks] = React.useState(null);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [openProviderSelection, setOpenProviderSelection] = React.useState(
    null
  );
  const [
    openProviderSelectionModal,
    setOpenProviderSelectionModal,
  ] = React.useState(false);

  let getIsMobile = () => {
    return isMobileOnly;
  };

  let isMobile = getIsMobile();

  const getSelected = () => {
    let url = location.pathname.replace("{", "").replace("}", "");

    let itemUrl = "";

    let bookmarks =
      props.bookmarks.length !== 0
        ? props.bookmarks
        : props.session.user.bookmarks;

    let menuItem = bookmarks.find((item) => {
      return item.url === url;
    });

    if (menuItem === undefined) {
      for (let item of bookmarks) {
        itemUrl = menuItemsService.getItemUrl(item.url);

        if (
          itemUrl !== null &&
          url.includes(itemUrl.slice(itemUrl.lastIndexOf("%") + 1))
        ) {
          return item;
        }
      }
    } else {
      return menuItem;
    }
  };

  let BmSelected = getSelected();

  const handleProfileClick = (event) => {
    setOpenProfile(event.currentTarget);
  };

  const handleSettingsClick = (event) => {
    setOpenSettings(event.currentTarget);
  };

  const handleBookmarksClick = (event) => {
    setOpenBookmarks(event.currentTarget);
  };

  const clearSearchField = (event) => {
    setSearchValue("");
  };

  const setValueSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const searchContract = (event) => {
    if (event.charCode === 13) {
      let searchPlayers = { CONTRACT_IDENTITY: event.target.value };
      let savedFilters = sessionStorage.filters;
      if (savedFilters != null) {
        savedFilters = JSON.parse(sessionStorage.filters);
      } else {
        savedFilters = {};
      }
      savedFilters.searchPlayers = searchPlayers;
      sessionStorage.setItem("filters", JSON.stringify(savedFilters));
      props.searchContractHeaderHandler(event.target.value);
      const searchPlayerUrl = "/players/searchplayers";
      const { pathname } = history.location;
      if (searchPlayerUrl !== pathname) {
        history.push(searchPlayerUrl);
      }
    }
  };

  const handleProviderSelectionClick = (event) => {
    setOpenProviderSelection(event.currentTarget);
  };

  const handleProviderSelectionModal = (newState) => {
    setOpenProviderSelectionModal(newState);
  };

  const handleSearchClick = (isOpen) => {
    setOpenSearch(isOpen);
  };

  const logo =
    process.env.REACT_APP_IMAGEPATH +
    (theme.palette.type === "dark"
      ? "logo_darkmode@2x.png"
      : "logo_lightmode@2x.png");

  const { user, brandSelected, partnerSelected } = props.session;

  return (
    <AppBar
      color={"default"}
      className={classes.root}
      elevation={0}
      position={"fixed"}
    >
      <Toolbar disableGutters={true} className={classes.toolbar}>
        {isMobileOnly && !openSearch && (
          <IconButton
            aria-label="open drawer"
            edge="start"
            className={classes.menuButton}
            onClick={props.handleOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!openSearch && (
          <img
            className={clsx({ [classes.logo]: !isMobile }, classes.logoHeight)}
            src={logo}
            height={50}
            alt={process.env.REACT_APP_TITLE}
          />
        )}

        <Hidden xsDown>
          <Divider orientation="vertical" flexItem />
          <Box className={classes.buttonContainer} minWidth="max-content">
            <Button
              disabled={user.brandCode !== -1 && user.partnerCode !== -1}
              variant={"text"}
              classes={{
                startIcon: classes.startIcon,
                endIcon: classes.endIcon,
              }}
              className={openProviderSelection && "active"}
              aria-label="Profile"
              aria-haspopup="true"
              onClick={handleProviderSelectionClick}
              startIcon={
                <img
                  className={classes.providerIcon}
                  src={partnerSelected.icon}
                  height={50}
                  width={50}
                  alt=""
                />
              }
              endIcon={<ExpandMoreIcon />}
            >
              <div>
                <Typography align="left" className={classes.fontBold}>
                  {brandSelected.name}
                </Typography>
                <Typography align="left">{partnerSelected.name}</Typography>
              </div>
            </Button>
            <ProviderSelectionPopover
              open={openProviderSelection}
              setOpen={setOpenProviderSelection}
            />
          </Box>
        </Hidden>

        <div className={classes.grow} />
        {(!isMobile || openSearch) && (
          <Box
            width={isMobile && openSearch ? "100%" : "auto"}
            mr="10px"
            className={classes.boxAdornment}
          >
            <Input
              className={clsx(classes.InputAdornment, {
                [classes.openSearch]: isMobile && openSearch,
              })}
              inputProps={{ "aria-label": "search" }}
              placeholder={t({
                needle: "label.searchCriteria",
              })}
              startAdornment={
                <InputAdornment position={"start"}>
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position={"end"}>
                  <IconButton onClick={clearSearchField}>
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              }
              onChange={setValueSearch}
              value={searchValue}
              onKeyPress={searchContract}
            />
          </Box>
        )}
        {isMobile && !openSearch && (
          <Box
            className={classes.buttonContainer}
            width={isMobile ? "10%" : "auto"}
            mr={isMobile && "10px"}
          >
            <Button
              variant={"text"}
              aria-label="Search"
              aria-haspopup="true"
              onClick={() => handleSearchClick(!openSearch)}
            >
              <SvgIcon
                component={SearchIcon}
                className={classes.searchIcon}
                viewBox={"0 0 30 30"}
              />
            </Button>
          </Box>
        )}

        {isMobile && openSearch && (
          <Button
            variant={"text"}
            className={classes.cancelButton}
            onClick={() => handleSearchClick(!openSearch)}
          >
            {t({ needle: "button.cancel" })}
          </Button>
        )}

        <Divider
          orientation="vertical"
          flexItem
          className={clsx({ [classes.hide]: isMobile })}
        />

        {!openSearch && (
          <Box
            className={clsx(classes.buttonContainer, isMobile ? "mobile" : "")}
            width={isMobile ? "10%" : "auto"}
          >
            <Button
              variant={"text"}
              classes={{
                startIcon: classes.startIcon,
                endIcon: classes.endIcon,
              }}
              className={openProfile && "active"}
              aria-label="Profile"
              aria-haspopup="true"
              onClick={handleProfileClick}
              startIcon={
                user.profileImage ? (
                  <img
                    src={user.profileImage}
                    height={isMobile ? 43 : 35}
                    width={isMobile ? 43 : 35}
                    style={{ borderRadius: 50 }}
                  />
                ) : (
                  <SvgIcon
                    component={IconAvatar}
                    viewBox={isMobile ? "0 0 43 43" : "0 0 35 35"}
                    className={classes.svgIcon}
                  />
                )
              }
              endIcon={
                <ExpandMoreIcon
                  className={clsx({ [classes.hide]: isMobile })}
                />
              }
            >
              <Hidden xsDown>
                <div>
                  <Typography align="left" className={classes.fontBold}>
                    {user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography align="left"> {user.role.name}</Typography>
                </div>
              </Hidden>
            </Button>
            <ProfileDropdown open={openProfile} setOpen={setOpenProfile} />
          </Box>
        )}

        <Divider
          orientation="vertical"
          flexItem
          className={clsx({ [classes.hide]: isMobile })}
        />

        {!openSearch && (
          <Box
            className={clsx(classes.buttonContainer, isMobile ? "mobile" : "")}
            width={isMobile ? "10%" : "auto"}
            mr={isMobile ? "10px" : 0}
          >
            <Button
              variant={"text"}
              aria-label="Settings"
              aria-haspopup="true"
              onClick={handleSettingsClick}
              className={openSettings && "active"}
            >
              <SvgIcon
                component={SettingsIcon}
                className={classes.svgIcon}
                viewBox={isMobile ? "0 0 43 43" : "0 0 35 35"}
              />
            </Button>
            <SettingsDropdown open={openSettings} setOpen={setOpenSettings} />
          </Box>
        )}

        <Divider
          orientation="vertical"
          flexItem
          className={clsx({ [classes.hide]: isMobile })}
        />

        {!isMobile && (
          <Box
            className={classes.buttonContainer}
            display={{ xs: "none", sm: "block" }}
          >
            <Button
              variant={"text"}
              aria-label="Favourite"
              onClick={handleBookmarksClick}
              className={
                classes.bookmarkIcon +
                " " +
                (BmSelected ? classes.selectedBookmark : "") +
                " " +
                (openBookmarks ? "active" : "")
              }
            >
              <SvgIcon
                component={FavouriteIcon}
                className={classes.svgIcon}
                viewBox={isMobile ? "0 0 43 43" : "0 0 35 35"}
              />
            </Button>
            <BookmarksDropdown
              bookmarkSelected={BmSelected}
              open={openBookmarks}
              setOpen={setOpenBookmarks}
            />
          </Box>
        )}
      </Toolbar>
      <Hidden smUp>
        <Box
          height={40}
          display={"flex"}
          alignItems={"center"}
          p={"5px"}
          pr={1}
          className={classes.providerBar}
          onClick={() => handleProviderSelectionModal(true)}
        >
          <Box mr={1}>
            <img
              style={{ display: "block" }}
              src={partnerSelected.icon}
              height={30}
              width={30}
              alt=""
            />
          </Box>
          <Box fontWeight={"fontWeightBold"}>{brandSelected.name}</Box>
          <Box flexGrow={1}>&nbsp;-&nbsp;{partnerSelected.name}</Box>
          <Box>
            <Edit color={"primary"} />
          </Box>
        </Box>
        <ProviderSelectionModal
          open={openProviderSelectionModal}
          setOpen={handleProviderSelectionModal}
        />
      </Hidden>
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  const { session } = state.AuthenticationReducer;
  const { bookmarks } = state.BookmarksReducer;

  return { session, bookmarks };
};

const mapDispatchToProps = {
  searchContractHeaderHandler,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
