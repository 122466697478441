import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  ADD_DOCUMENT_FAILURE,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCESS,
  DOCUMENT_TYPES_FAILURE,
  DOCUMENT_TYPES_SUCCESS,
  PLAYER_DOCUMENTS_FAILURE,
  PLAYER_DOCUMENTS_HISTORY_FAILURE,
  PLAYER_DOCUMENTS_HISTORY_SUCCESS,
  PLAYER_DOCUMENTS_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.player.playerDocuments.";
const attributesDateReformat = ["EXPIRATION_DATE", "RELEASE_DATE"]
const initialState = {
  loading: true,
  documentsInfo: null,
  documentHistory: {
    loading: true,
    data: [],
    columns: [
      {
        field: "documentTypeId",
        title: labelPrefix + "documentTypeId",
        render: (rowData) => {
          if (rowData["documentTypeId"] != null) {
            return (
              <Translate
                needle={`${labelPrefix}${rowData["documentTypeId"]}`}
              />
            );
          }
        },
      },
      {
        field: "attributeId",
        title: labelPrefix + "attributes.attributeId",
      },
      {
        field: "oldValue",
        title: labelPrefix + "attributes.oldValue",
        render:  (rowData) => {
          if (rowData.attributeId && attributesDateReformat.includes(rowData.attributeId)  && rowData.oldValue) {
            return (
              <DateFormatter format={"L"} value={rowData.oldValue} />
            );
          }
          return rowData.oldValue
        },
      },
      {
        field: "newValue",
        title: labelPrefix + "attributes.newValue",
        render:  (rowData) => {
          if (rowData.attributeId && attributesDateReformat.includes(rowData.attributeId)  && rowData.newValue) {
            return (
              <DateFormatter format={"L"} value={rowData.newValue} />
            );
          }
          return rowData.newValue
        },
      },
      {
        field: "externalTransactionId",
        title: labelPrefix + "attributes.externalTransactionId",
      },
      {
        field: "boOperator",
        title: labelPrefix + "attributes.boOperator",
      },
      {
        field: "reason",
        title: labelPrefix + "attributes.reason",
      },
      {
        field: "creationDate",
        title: labelPrefix + "attributes.creationDate",
        render: (rowData) => {
          if (rowData.creationDate != null) {
            return (
              <DateFormatter format={"L LT"} value={rowData.creationDate} />
            );
          }
        },
      },
    ],
  },
  documentTypes: [],
};

const PlayerDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documentsInfo: action.payload,
        loading: false,
      };
    }
    case PLAYER_DOCUMENTS_FAILURE: {
      return {
        ...state,
        documentsInfo: null,
        loading: false,
      };
    }
    case ADD_DOCUMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_DOCUMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case PLAYER_DOCUMENTS_HISTORY_SUCCESS: {
      return {
        ...state,
        documentHistory: {
          ...state.documentHistory,
          loading: false,
          data: action.payload,
        },
      };
    }
    case PLAYER_DOCUMENTS_HISTORY_FAILURE: {
      return {
        ...state,
        documentHistory: {
          ...state.documentHistory,
          loading: false,
          data: null,
        },
      };
    }

    case DOCUMENT_TYPES_SUCCESS: {
      return {
        ...state,
        documentTypes: action.payload,
      };
    }
    case DOCUMENT_TYPES_FAILURE: {
      return {
        ...state,
        documentTypes: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default PlayerDocumentsReducer;
