import {
  ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS,
  ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE,
  ACCOUNT_TRANSACTION_SUCCESS,
  ACCOUNT_TRANSACTION_DETAIL_SUCCESS,
  ACCOUNT_TRANSACTION_FAILURE,
  ACCOUNT_TRANSACTION_DETAIL_FAILURE,
  ACCOUNT_TRANSACTION_REQUEST,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import Currency from "../../../../Layouts/Currency/Currency";
import * as moment from "moment";
import Translate from "../../../../Layouts/Translator/Translate";

const getDescriptionPhase = (phaseId) => {
  return phaseId ? (
    <Translate needle={"label.panel.phaseDescription." + phaseId} />
  ) : null;
};
const initialState = {
  transactionsList: {
    idKey: "massiveId",
    searchCriteria: [],
    sort: { field: "transaction", order: "desc" },
    columns: [
      {
        field: "transaction",
        title: "label.player.accountTransactions.reserveId",
        default: true,
      },
      {
        field: "confirmTransactionId",
        title: "label.player.accountTransactions.confirmTransactionId",
      },
      {
        field: "dateLong",
        title: "label.player.accountTransactions.date",
        render: (rowData) => {
          if (rowData["dateLong"]) {
            return <Moment format="L LT">{rowData["dateLong"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["dateLong"]) {
            return moment(rowData["dateLong"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "amount",
        title: "label.player.accountTransactions.amount",
        render: (rowData) => {
          if (rowData["amount"]) {
            return (
              <Currency
                amount={rowData["amount"]}
                currency={rowData["currency"]}
                justify="flex-end"
              />
            );
          }
        },
        default: true,
      },
      {
        field: "currency",
        title: "label.player.accountTransactions.currency",
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge currency={rowData["currency"]} justify="center" />
            );
          }
        },
      },
      {
        field: "reportCause",
        title: "label.player.accountTransactions.reportCause",
      },
      {
        field: "external",
        title: "label.player.accountTransactions.external",
      },
      {
        field: "externalSystem",
        title: "label.player.accountTransactions.externalSystem",
      },
      {
        field: "gameName",
        title: "label.player.accountTransactions.gameName",
      },
      {
        field: "platformId",
        title: "label.player.accountTransactions.platformId",
      },
    ],
    data: [],
    loading: true,
  },
  transactionDetails: {
    fields: [
      {
        field: "transactionId",
        title: "label.player.accountTransactions.reserveId",
      },
      {
        field: "confirmTransactionId",
        title: "label.player.accountTransactions.confirmTransactionId",
      },
      {
        field: "operationId",
        title: "label.player.accountTransactions.operationId",
      },
      { field: "phase", title: "label.player.accountTransactions.phase" },
      {
        field: "transactionDate",
        title: "label.player.accountTransactions.transactionDate",
        render: (data) => {
          if (data.transactionDate) {
            return <Moment format="L LT">{data.transactionDate}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "externalTransactionId",
        title: "label.player.accountTransactions.externalTransactionId",
      },
      {
        field: "gameSystemId",
        title: "label.player.accountTransactions.gameSystemId",
      },
      { field: "reason", title: "label.player.accountTransactions.reason" },
      {
        field: "callerClientId",
        title: "label.player.accountTransactions.callerClientId",
      },
      {
        field: "callerChannelDesc",
        title: "label.player.accountTransactions.callerChannelDesc",
      },
      {
        field: "currencyTransaction",
        title: "label.player.accountTransactions.currencyTransaction",
        render: (data) => {
          if (data.currencyTransaction) {
            return <CurrencyBadge currency={data.currencyTransaction} />;
          } else {
            return null;
          }
        },
      },
      {
        field: "amountTransaction",
        title: "label.player.accountTransactions.amountTransaction",
        render: (data) => {
          if (data.amountTransaction) {
            return (
              <Currency
                amount={data.amountTransaction}
                currency={data.currencyTransaction}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "amountUserExchange",
        title: "label.player.accountTransactions.amountUserExchange",
        render: (data) => {
          if (data.amountUserExchange) {
            return (
              <Currency
                amount={data.amountUserExchange}
                currency={data.currencyTransaction}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "amountWalletExchange",
        title: "label.player.accountTransactions.amountWalletExchange",
        render: (data) => {
          if (data.amountWalletExchange) {
            return (
              <Currency
                amount={data.amountWalletExchange}
                currency={data.currencyTransaction}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "availableBalance",
        title: "label.player.accountTransactions.availableBalance",
        render: (data) => {
          if (data.availableBalance) {
            return (
              <Currency
                amount={data.availableBalance}
                currency={data.currencyTransaction}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "accountBalance",
        title: "label.player.accountTransactions.accountBalance",
        render: (data) => {
          if (data.accountBalance) {
            return (
              <Currency
                amount={data.accountBalance}
                currency={data.currencyTransaction}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "exchangeUserRate",
        title: "label.player.accountTransactions.exchangeUserRate",
      },
      {
        field: "exchangeRateWallet",
        title: "label.player.accountTransactions.exchangeRateWallet",
      },
      {
        field: "reportCause",
        title: "label.player.accountTransactions.reportCause",
      },
      {
        field: "gameName",
        title: "label.player.accountTransactions.gameName",
      },
      {
        field: "platformId",
        title: "label.player.accountTransactions.platformId",
      }
    ],
    data: [],
  },
};

const AccountTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          searchCriteria: action.payload,
        },
      };
    }

    case ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE: {
      return {
        ...state,
        transactionsList: { ...state.transactionsList, searchCriteria: null },
      };
    }

    case ACCOUNT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          data: action.payload,
          loading: false,
          sort: action.payload.order,
        },
      };
    }

    case ACCOUNT_TRANSACTION_FAILURE: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          data: null,
          loading: false,
        },
      };
    }

    case ACCOUNT_TRANSACTION_REQUEST: {
      return {
        ...state,
        transactionsList: { ...state.transactionsList, loading: true },
      };
    }

    case ACCOUNT_TRANSACTION_DETAIL_SUCCESS: {
      const transactionData = {
        transactionId: action.payload.transactionId,
        confirmTransactionId: action.payload.confirmTransactionId,
        operationId: action.payload.operationId,
        phase: getDescriptionPhase(action.payload.phase),
        transactionDate: action.payload.transactionDate,
        externalTransactionId: action.payload.externaltransactionId,
        gameSystemId: action.payload.gameSystemId,
        reason: action.payload.reasone,
        callerClientId: action.payload.callerClientId,
        callerChannelDesc: action.payload.callerChannelDesc,
        currencyTransaction: action.payload.currencyTransaction,
        amountTransaction: action.payload.amountTransaction,
        amountUserExchange: action.payload.amountUserExchange,
        amountWalletExchange: action.payload.amountWalletExchange,
        availableBalance: action.payload.availableBalance,
        accountBalance: action.payload.accountBalance,
        exchangeUserRate: action.payload.exchangeUserRate,
        exchangeRateWallet: action.payload.exchangeRateWallet,
        reportCause: action.payload.reportCause,
        gameName: action.payload.gameName,
        platformId: action.payload.platformId
      };

      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          data: transactionData,
        },
      };
    }

    case ACCOUNT_TRANSACTION_DETAIL_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          data: null,
          fields: null,
        },
      };
    }

    default:
      return state;
  }
};

export default AccountTransactionsReducer;
